import { createRouter, createWebHistory } from 'vue-router';

import Home from './pages/Home.vue';
import Devices from './pages/Devices.vue';
import DeviceReport from './pages/DeviceReport.vue';
import Gateways from './pages/Gateways.vue';
import GatewayReport from './pages/GatewayReport.vue';
import Scan from './pages/Scan.vue';
import DeviceDetail from './pages/DeviceDetail.vue';
import NewDevice from './pages/NewDevice.vue';
import GatewayDetail from './pages/GatewayDetail.vue';
import NewGateway from './pages/NewGateway.vue';
import Reports from './pages/Reports.vue';

const routes = [
  {
    name: '',
    path: '/',
    component: Home,
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
  },
  {
    name: 'scan',
    path: '/scan',
    component: Scan,
  },
  {
    name: 'devices',
    path: '/devices',
    component: Devices,
  },
  {
    name: 'device-report',
    path: '/device-report',
    component: DeviceReport,
  },
  {
    name: 'new-device',
    path: '/new-device',
    component: NewDevice,
  },
  {
    name: 'device-detail',
    path: '/devices/:id',
    component: DeviceDetail,
  },
  {
    name: 'gateways',
    path: '/gateways',
    component: Gateways,
  },
  {
    name: 'gateway-report',
    path: '/gateway-report',
    component: GatewayReport,
  },
  {
    name: 'new-gateway',
    path: '/new-gateway',
    component: NewGateway,
  },
  {
    name: 'gateway-detail',
    path: '/gateways/:id',
    component: GatewayDetail,
  },

  {
    name: 'reports',
    path: '/reports',
    component: Reports,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // console.log('scrollBehavior()');
    // console.log(to);
    // console.log(from);
    return { top: 0 };
  },
});

router.beforeEach(function(to, from, next) {
  // store.commit({
  //   type: 'updateUserAlert',
  //   visible: false,
  // });
  next();
});

export default router;
