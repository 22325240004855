<template>
  <button
    v-if="state == 'none'"
    type="submit"
    class="w-32 h-9 ml-3 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none "
  >
    <p>
      {{ text }}
    </p>
  </button>
  <button
    v-else-if="state == 'loading'"
    type="submit"
    class="w-32 h-9 ml-3 inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-300 "
  >
    <p>
      <svg
        baseProfile="full"
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        preserveAspectRatio="xMidYMid meet"
        id="svg_document"
        style="zoom: 1;"
        visibility="visible"
      >
        <title id="svg_document_title" visibility="visible">
          corethings.svg
        </title>
        <defs id="svg_document_defs" visibility="visible"></defs>
        <g id="main_group" visibility="visible"></g>
        <circle
          id="core"
          cx="16px"
          cy="16px"
          r="8px"
          fill="white"
          visibility="visible"
        ></circle>
        <circle
          id="trajectory"
          cx="16px"
          cy="16px"
          r="12px"
          fill="none"
          stroke="white"
          stroke-width="1px"
          visibility="visible"
        ></circle>
        <circle
          id="thing_loading"
          cx="16px"
          cy="4px"
          r="2px"
          fill="lightgrey"
          stroke="none"
          visibility="visible"
        ></circle>
        <circle
          id="thing_constant"
          cx="16px"
          cy="4px"
          r="2px"
          fill="white"
          stroke="none"
          visibility="visible"
        ></circle>
      </svg>
    </p>
  </button>
  <button
    v-else-if="state == 'success'"
    class="w-32 h-9 ml-3 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 focus:outline-none "
  >
    <p>
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        />
      </svg> -->
      {{ text }}
    </p>
  </button>
  <button
    v-else-if="state == 'error'"
    class="w-32 h-9 ml-3 text-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 focus:outline-none "
  >
    <p>
      {{ text }}
    </p>
  </button>
</template>

<script>
export default {
  props: {
    text: { required: true, type: String },
    state: { required: false, type: String },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
