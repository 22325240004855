<template>
  <div class="bg-gray-100 px-4 py-5 border-b border-gray-200 mb-0 mt-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Functional Testing Reports
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      Reports for the provisioning and testing of both Gateways and Devices
    </p>
  </div>

  <div
    class="rounded-lg bg-gray-100 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px"
  >
    <div
      v-for="(report, reportIdx) in functional_test_reports"
      :key="report.title"
      :class="[
        reportIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
        reportIdx === 1 ? 'sm:rounded-tr-lg' : '',
        reportIdx === functional_test_reports.length - 2
          ? 'sm:rounded-bl-lg'
          : '',
        reportIdx === functional_test_reports.length - 1
          ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
          : '',
        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
      ]"
    >
      <div>
        <span
          :class="[
            report.iconBackground,
            report.iconForeground,
            'rounded-lg inline-flex p-3 ring-4 ring-white',
          ]"
        >
          <component :is="report.icon" class="h-6 w-6" aria-hidden="true" />
        </span>
      </div>
      <div class="mt-8">
        <h3 class="text-lg font-medium">
          <router-link :to="report.href" class="focus:outline-none">
            <!-- Extend touch target to entire panel -->
            <span class="absolute inset-0" aria-hidden="true" />
            {{ report.title }}
          </router-link>
        </h3>
        <p class="mt-2 text-sm text-gray-500">
          {{ report.description }}
        </p>
      </div>
      <span
        class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import {
  ChartBarIcon,
  LightningBoltIcon,
  GlobeAltIcon,
  UserGroupIcon,
  ShoppingCartIcon,
  SparklesIcon,
  DownloadIcon,
  LinkIcon,
} from '@heroicons/vue/outline';

const functional_test_reports = [
  {
    title: 'Gateway Provisioning Report',
    href: '/gateway-report',
    icon: GlobeAltIcon,
    description: 'Report of all Gateways provisioned',
    iconForeground: 'text-green-700',
    iconBackground: 'bg-green-50',
  },

  {
    title: 'Device Testing Report',
    href: '/device-report',
    icon: ChartBarIcon,
    description: 'Record of all devices fully tested',
    iconForeground: 'text-blue-700',
    iconBackground: 'bg-blue-50',
  },
];

export default {
  setup() {
    return {
      functional_test_reports,
    };
  },
};
</script>
