<template>
  <div class="sm:mx-6 lg:mx-8">
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="item in test_stats"
        :key="item.id"
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
      >
        <dt>
          <div class="absolute bg-purple-50 rounded-md p-3">
            <component
              :is="item.icon"
              class="h-6 w-6 text-purple-700"
              aria-hidden="true"
            />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 pb-1 flex items-baseline sm:pb-1">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>

          <!-- <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div class="text-sm">
            <a
              href="#"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              View all<span class="sr-only"> {{ item.name }} stats</span></a
            >
          </div>
        </div> -->
        </dd>
      </div>
    </dl>

    <div class="py-6 px-1">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-2 px-0 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      DEV EUI
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Connection Pass
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Battery Pass
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Product Name
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Seen
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Battery (v)
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Temperature
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Door Open
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Counter
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(test, testIdx) in devicetests"
                    :key="test.dev_eui"
                    :class="[testIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50']"
                  >
                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {{ test.dev_eui }}
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ test.pass_connection_test }}
                    </td>
                    <td
                      class="flex px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ test.pass_battery_test }}
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ test.product_name }}
                    </td>

                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{
                        getTimeDiff(
                          dateMath.diff(
                            new Date(test.last_seen),
                            new Date(),
                            'minutes',
                            false
                          )
                        )
                      }}
                    </td>

                    <td
                      class="flex px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      <div v-if="test.battery">
                        {{ test.battery.toFixed(2) }}
                      </div>
                      <div v-if="test.battery && test.battery < 3" class="ml-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="red"
                        >
                          <path
                            d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
                          />
                        </svg>
                      </div>
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ test.temperature_internal }}
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ test.door_open }}
                    </td>
                    <td
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ test.counter }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <server-response :response="serverResponse"></server-response>

      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import { SearchIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';
import GestureDoubleTap from 'vue-material-design-icons/GestureDoubleTap';
import CheckboxMarkedCircleOutline from 'vue-material-design-icons/CheckboxMarkedCircleOutline';
import BatteryChargingWirelessAlert from 'vue-material-design-icons/BatteryChargingWirelessAlert';
import Run from 'vue-material-design-icons/Run';
import Thermometer from 'vue-material-design-icons/Thermometer';
import DoorOpen from 'vue-material-design-icons/DoorOpen';

export default {
  components: {
    ServerResponse,
    SearchIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    XIcon,
    GestureDoubleTap,
    CheckboxMarkedCircleOutline,
    BatteryChargingWirelessAlert,
    Run,
    Thermometer,
    DoorOpen,
  },

  data() {
    return {
      devicetests: [],
      dateMath,
      serverResponse: '',
      test_stats: [],
      traffic_count: 0,
      temp_count: 0,
      door_count: 0,
      test_fail_count: 0,
      test_pass_count: 0,
    };
  },
  methods: {
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
  },

  created() {
    this.test_pass_count = 0;
    this.test_fail_count = 0;
    this.door_count = 0;
    this.traffic_count = 0;
    this.temp_count = 0;

    axios
      .get('/devicetests')
      .then((res) => {
        this.serverResponse = JSON.stringify(res, null, 2);
        this.devicetests = res.data.data;

        console.log('Number of tests: ' + this.devicetests.length);

        for (const test of this.devicetests) {
          if (test.pass_connection_test && test.pass_battery_test) {
            this.test_pass_count++;

            if (test.product_name == 'core-01-868-door-sensor') {
              this.door_count++;
            } else if (test.product_name == 'core-01-868-traffic-sensor') {
              this.traffic_count++;
            } else if (test.product_name == 'core-01-868-temp-sensor') {
              this.temp_count++;
            }
          } else {
            this.test_fail_count++;
          }
        }

        this.test_stats.push({
          name: 'Total Nodes Tested',
          stat: this.devicetests.length,
          icon: GestureDoubleTap,
        });
        this.test_stats.push({
          name: 'Nodes Passed',
          stat: this.test_pass_count,
          icon: CheckboxMarkedCircleOutline,
        });
        this.test_stats.push({
          name: 'Nodes Failed',
          stat: this.test_fail_count,
          icon: BatteryChargingWirelessAlert,
        });
        this.test_stats.push({
          name: 'Traffic Nodes Passed',
          stat: this.traffic_count,
          icon: Run,
        });
        this.test_stats.push({
          name: 'Door Nodes Passed',
          stat: this.door_count,
          icon: DoorOpen,
        });
        this.test_stats.push({
          name: 'Temperature Nodes Passed',
          stat: this.temp_count,
          icon: Thermometer,
        });
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });
  },
};
</script>
