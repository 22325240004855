<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="showAllSteps"
  >
    <div>
      <p class="ml-5 mt-2 mb-20 text-md font-medium text-gray-600">
        Log onto
        <span class="text-indigo-600">https://eng.corethings.io</span> with your
        username & password and click on
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Gateway
        </button>
      </p>
    </div>
  </div>

  <!-- Step 0   -->

  <div class="bg-white shadow overflow-hidden sm:rounded-md m-5">
    <div class="grid grid-cols-2" v-on:click="moveToStep(0)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          0. Download latest Firmware
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[0].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[0].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4">
          <div>
            <p class="text-xs font-sm text-gray-600 mt-2">
              We are currently using firmware version
              <span class="text-red-600"
                >dragino-lgw--v5.4.1666149468-squashfs-sysupgrade</span
              >. Please download this from
              <a
                class="text-blue-600"
                href="https://drive.google.com/file/d/1wBBxs5f8wwY0bFc1LikIM5DqOoHAYnt6/view?usp=share_link"
                >here</a
              >
              and save it locally on your computer. You will need this at a
              later step
            </p>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(1)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 0    -->
  <!-- Step 1   -->

  <div
    v-if="current_step > 0 || showAllSteps"
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(1)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          1. Put QR codes on the Gateway
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[1].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[1].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div>
            <p class="text-xs font-sm text-gray-600 mt-2">
              Note the serial number on the back of the gateway, starting with
              LG308... Find associated QR codes for this serial number on the
              labels provided
            </p>
          </div>
          <div class="hidden md:block">
            <div>
              <img src="../assets/gateway_front.png" class="h-48 w-auto" />
            </div>
          </div>
          <div class="hidden md:block">
            <div>
              <img src="../assets/gateway_back.png" class="h-48 w-auto" />
            </div>
          </div>
          <div class="hidden md:block">
            <div>
              <img src="../assets/gateway_box.png" class="h-32 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(2)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 1    -->

  <!-- Step 2   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 1 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(2)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          2. Scan Gateway
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[2].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[2].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
          <div class="flex items-stretch ml-20">
            <button
              type="button"
              class="inline-flex self-center max-w-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              v-on:click="loadScanner"
            >
              Scan
            </button>
          </div>
          <div class="col-span-2 mr-10">
            <div class="flex grid grid-cols-2">
              <div class="text-sm font-sm text-gray-600 mt-2 mr-10">
                Gateway EUI:
              </div>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="gateway_eui"
                  id="gateway_eui"
                  class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                  v-model="gateway.gateway_eui"
                />
              </div>
            </div>
            <div class="flex grid grid-cols-2">
              <div class="text-sm font-sm text-gray-600 mt-2 mr-10">
                Hostname:
              </div>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="gateway_serial"
                  id="gateway_serial"
                  class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                  v-model="gateway.hostname"
                />
              </div>
            </div>
          </div>
          <div v-if="gatewayAlreadyInstalled">
            <div class="text-sm mb-2 ml-4">
              Gateway already installed - Remove gateway to proceed with a clean
              provision
            </div>

            <div>
              <feedback-button
                v-if="deleteButtonState == 'none'"
                v-on:click="confirmRemoveGateway = true"
                :text="'Remove Gateway'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'success'"
                :state="'success'"
                :text="'Removed!'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
              <confirm-action
                v-if="confirmRemoveGateway"
                :title_text="'Remove Gateway'"
                :message_text="'Are you sure you want to Remove this Gateway? This action cannot be undone!'"
                :confirm_button_text="'Remove Gateway'"
                @userAction="removeGateway($event)"
              ></confirm-action>
            </div>
            <div class="text-xs mt-5 ml-4" v-on:click="moveToStep(8)">
              (Jump to last step)
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          v-if="
            gateway.gateway_eui &&
            gateway.gateway_eui.length > 0 &&
            !gatewayAlreadyInstalled
          "
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(3)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <div class="p-3 w-full border" v-if="showScanner">
    <core-scanner
      @decode="onDecode"
      @userAction="scannerButtonClick($event)"
    ></core-scanner>
  </div>

  <!-- End of Step 2    -->

  <!-- Step 3   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 2 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(3)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          3. Input SIM Card information
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[3].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[3].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-6">
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-0 mr-10">
                Submit the last 7 digits of the new SIM Card to populate all
                information needed
              </div>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  v-model="eid_digits"
                  class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                />
              </div>
              <div class="flex mt-5 mb-10 col-span-2">
                <div>
                  <button
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    v-on:click="findSimInfo"
                  >
                    Submit
                  </button>
                </div>
                <div class="ml-5 text-xs text-red-400" v-if="simUserMessage">
                  {{ simUserMessage }}
                </div>
              </div>
              <div>
                <div class="text-xs font-sm text-gray-600 mt-2 mr-10">
                  SIM EID:
                </div>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    class="block w-full pr-0 border-gray-200 sm:text-sm rounded-md"
                    v-model="gateway.sim_eid"
                  />
                </div>
              </div>
              <div>
                <div class="text-xs font-sm text-gray-600 mt-2 mr-10">
                  SIM ICCID:
                </div>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                    v-model="gateway.sim_iccid"
                  />
                </div>
              </div>
              <div>
                <div class="text-xs font-sm text-gray-600 mt-2 mr-10">
                  SIM PROVIDER:
                </div>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                    v-model="gateway.sim_provider"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              v-on:click="getConnectedYouURL()"
            >
              Activate SIM
            </button>
            <img class="mt-2" src="../assets/connected_you_activation.png" />
          </div>
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-5 mb-2">
                Insert the SIM card into the back of the gateway. Ensure you
                feel it clicking into place
              </div>
            </div>
            <img src="../assets/sim_card.png" />
          </div>
        </div>
      </div>
      <div>
        <!-- <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-if="gateway.sim_eid"
          v-on:click="(moveToStep(3),(updateGateway))"
        >
          Next
        </button> -->
        <feedback-button
          v-if="submitButtonState == 'none'"
          v-on:click="updateGateway"
          :text="'Next'"
          :state="'none'"
        ></feedback-button>
        <feedback-button
          v-else-if="submitButtonState == 'loading'"
          :state="'loading'"
        ></feedback-button>
        <feedback-button
          v-else-if="submitButtonState == 'success'"
          :state="'success'"
          :text="'Installed..'"
        ></feedback-button>
        <feedback-button
          v-else-if="submitButtonState == 'error'"
          :state="'error'"
          :text="'Error!'"
        ></feedback-button>
      </div>
    </div>
  </div>

  <!-- End of Step 3    -->
  <!-- Step 4   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 3 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(4)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          4. Power the gateway and connect
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[4].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[4].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2">
              <div>
                Connect all leads to the Gateway except for LoRa. Connect the
                WAN cable to an internet connection and connect the LAN cable to
                your computer.
              </div>
              <img src="../assets/gateway_leads.png" class="h-32 w-auto" />
            </div>
          </div>
          <div class="hidden md:block">
            <div>
              <div class="text-xs font-sm text-gray-600 mt-2">
                open
                <a
                  href="http://10.130.1.1"
                  target="_blank"
                  class="text-blue-500"
                >
                  http://10.130.1.1
                </a>
                on your browser. Log in with the following credentials
              </div>
              <div class="text-xs font-sm mt-5">username: root</div>
              <div class="text-xs font-sm">password: dragino</div>
            </div>
          </div>
          <div class="hidden md:block">
            <div>
              <img src="../assets/gateway_login_2.png" class="h-32 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(5)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 4    -->
  <!-- Step 5   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 4 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(5)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          5. Update Gateway firmware
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[5].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[5].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                Navigate to the Firmware Upgrade screen on the gateway under the
                System menu
              </div>
              <div>
                <img src="../assets/firmware_1.png" class="h-48 w-auto" />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                Select the firmware file from your local machine and click
                "Upload". When the file has uploaded, click "Proceed". Leave the
                "Preserve Settings checkbox" unchecked
              </div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                MAKE SURE TO CLICK ON THE UPLOAD BUTTON FIRST
              </div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                THEN click the "Proceed" button
              </div>
              <div>
                <img src="../assets/firmware_2.png" class="h-48 w-auto" />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                The Gateway will take aproximately 3 minutes to fully update.
                Wait for the gateway to return to the main home screen before
                proceeding
              </div>
              <div>
                <img src="../assets/firmware_4.png" class="h-28 w-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(6)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 5    -->
  <!-- Step 6   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 5 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(6)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          6. Install Remote.it Remote Management Software
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[6].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[6].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 grid grid-cols-3 gap-8">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
              Navigate to the Remote.it screen on the gateway under the System
              menu
            </div>
            <div>
              <img src="../assets/remote_1.png" class="h-48 w-auto" />
            </div>
          </div>
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                Click on Install
              </div>
              <div>
                <img src="../assets/remote_2.png" class="h-24 w-auto" />
              </div>

              <div class="text-xs font-sm text-gray-600 mt-10 mb-2">
                Ensure you see the following message during install. If you do
                not see this message, then the software is not installing.
                Please check your internet connection on the Home Screen
              </div>
              <div>
                <img src="../assets/remote_3.png" class="h-18 w-auto" />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                Insert the following Licence Key
                <p class="text-xs text-red-600 mt-2 mb-2">
                  B932F7C2-8B6D-83F5-828E-F3A4D16B3022
                </p>
              </div>
              <div>
                <img src="../assets/remote_4.png" class="h-18 w-auto" />
              </div>
              <div class="text-xs font-sm text-gray-600 mt-6 mb-2">
                Click "Save" first and then click "Register"
              </div>

              <div class="text-xs font-sm text-gray-600 mt-0 mb-2">
                Confirm that Remote.it is both installed and registered
              </div>
              <img src="../assets/remote_5.png" class="h-18 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(7)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 6    -->
  <!-- Step 6   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 6 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(7)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          7. Configure LoRaWAN info
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[7].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-0 sm:px-6"
      v-if="!steps[7].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-10">
              Click on the LoRaWAN menu option in the Gateway
            </div>
            <img src="../assets/lorawan_1.png" class="h-32 w-auto mb-2" />
          </div>
          <div class="text-xs font-sm text-gray-600 md:block col-span-2">
            <div>
              <ol>
                <li class="mb-4">
                  1. Under Primary LoRaWan Server select "Custom/Private
                  LoRaWan" as the Service Provider.
                </li>
                <li class="mb-4">
                  2. Input
                  <b><i>corethings.eu1.cloud.thethings.industries</i></b> as the
                  Server Address. Leave all other settings the same and click
                  "Save & Apply"
                </li>
              </ol>
            </div>
            <img src="../assets/lorawan_3.png" class="h-48 w-auto mb-2" />
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(8)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 7    -->
  <!-- Step 8   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 7 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(8)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          8. Configure Cellular info
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[8].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[8].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-10">
              Disconnect the WAN cable from the Gateway
            </div>
            <img src="../assets/cellular_0.png" class="h-32 w-auto" />
          </div>
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-10">
              Select Cellular from the Network dropdown on the Gateway.
            </div>
            <div>
              <img src="../assets/cellular_1.png" class="h-32 w-auto" />
            </div>
          </div>
          <div class="hidden md:block">
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-10">
              <div class="mb-2">Under the <b>Cellular Settings</b></div>
              <div>gateway provider{{ gateway.sim_provider }}</div>
              <ol>
                <li class="mb-4">
                  1. <b>check</b> the "Enable Cellular WAN" option
                </li>
                <li class="mb-4">
                  2. <b>uncheck</b> the "Use Cellular as Backup WAN" option
                </li>
                <!-- <li v-if="gateway.sim_provider == 'MONOGOTO'">
                  3. Use <b><i>data.mono</i></b> as the APN value
                </li>
                <li v-else-if="gateway.sim_provider == 'TELNA'">
                  3. Use <b><i>data.telna</i></b> as the APN value
                </li> -->
                <li class="mb-4">
                  3. Under the <b>APN VALUE</b> Textbox, use
                  <span class="text-lg text-red-600">
                    <b><i>rainbow</i></b></span
                  >
                </li>
                <li class="mb-4">
                  4. Keep everything else the same and click "Save & Apply"
                </li>
              </ol>
            </div>
          </div>
          <div class="hidden md:block">
            <div>
              <img src="../assets/cellular_2.png" class="h-48 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(9)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 8    -->
  <!-- Step 9   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 8 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(9)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          9. Disable WiFi
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[9].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[9].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-10">
              Select WiFi from the Network dropdown on the Gateway.
            </div>
            <div>
              <img src="../assets/wifi_1.png" class="h-32 w-auto" />
            </div>
          </div>
          <div class="hidden md:block">
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-10">
              Under <b>WiFi Access Point Settings</b>, uncheck "Enable WiFi
              Access Point" and click "Save & Apply". <br /><br />To ensure the
              settings have been saved, click on the "Home" menu and wait for
              the Gateway home screen to show
            </div>
          </div>
          <div class="hidden md:block">
            <div>
              <img src="../assets/wifi_2.png" class="h-48 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(10)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 9    -->
  <!-- Step 10   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 9 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(10)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          10. Confirm Gateway Connection
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[10].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[10].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div class="col-span-2">
            <div class="text-xs font-sm text-gray-600 mt-2 mb-4 pr-20">
              Wait until you get a green checkmark on both the LoRaWAN service
              and the Cell service. This may take a few minutes.
            </div>
            <img src="../assets/connection_1.png" class="h-60 w-auto" />
          </div>
          <div class="hidden md:block mt-2 justify-end">
            <div>
              <button
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                v-on:click="refresh"
              >
                Test Gateway Status
              </button>
            </div>

            <div class="mt-20 ml-10" v-if="gatewaytest.pass_connection_test">
              <button
                type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent text-2xl font-medium rounded-md text-green-700 bg-green-100"
                disabled
              >
                PASS
              </button>
            </div>
            <div class="mt-20 ml-10" v-else>
              <button
                type="button"
                class="inline-flex justify-self-center items-center px-4 py-2 border border-transparent text-2xl font-medium rounded-2xl text-red-700 bg-red-100"
                disabled
              >
                FAIL
              </button>
            </div>

            <div class="text-xs mt-1 ml-10 mt-2">
              Last Seen

              {{ lastSeenInWords }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          v-if="gatewaytest.pass_connection_test"
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(11)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 10    -->
  <!-- Step 11   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 10 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(11)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          11. Verify Remote IT
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[11].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="justify-center px-4 py-4 sm:px-6"
      v-if="!steps[11].complete || showAllSteps"
    >
      <div
        class="w-full grid grid-cols-2 justify-items-stretch items-center border border-transparent"
      >
        <div>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-on:click="getRemoteITURL()"
          >
            Verify
          </button>

          <div class="mt-10">
            <img src="../assets/remote_it_verification.png" width="400" />
          </div>
        </div>

        <div class="border border-grey-200 justify-self-end mr-8">
          <button
            type="button"
            class="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-on:click="moveToStep(12)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- End of Step 11    -->
  <!-- Step 12   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 11 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(12)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600">
          12. Remove Gateway
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[12].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex justify-center px-4 py-4 sm:px-6"
      v-if="!steps[12].complete || showAllSteps"
    >
      <div v-if="gateway.live">
        <feedback-button
          v-if="deleteButtonState == 'none'"
          v-on:click="confirmRemoveGateway = true"
          :text="'Remove Gateway'"
          :state="'none'"
        ></feedback-button>
        <feedback-button
          v-else-if="deleteButtonState == 'loading'"
          :state="'loading'"
        ></feedback-button>
        <feedback-button
          v-else-if="deleteButtonState == 'success'"
          :state="'success'"
          :text="'Removed!'"
        ></feedback-button>
        <feedback-button
          v-else-if="deleteButtonState == 'error'"
          :state="'error'"
          :text="'Error!'"
        ></feedback-button>
        <confirm-action
          v-if="confirmRemoveGateway"
          :title_text="'Remove Gateway'"
          :message_text="'Are you sure you want to Remove this Gateway? This action cannot be undone!'"
          :confirm_button_text="'Remove Gateway'"
          @userAction="removeGateway($event)"
        ></confirm-action>
      </div>
      <div v-else>Completed!</div>

      <div>
        <!-- <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(9)"
        >
          Next
        </button> -->
      </div>
    </div>
  </div>

  <!-- End of Step 12    -->

  <server-response :response="serverResponse"></server-response>
  <p v-if="!showAllSteps" v-on:click="showAllSteps = true">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#D1D5DB"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </p>
  <p v-if="showAllSteps" v-on:click="showAllSteps = false">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#D1D5DB"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </p>
</template>

<script>
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  PlusIcon,
} from '@heroicons/vue/solid';
import axios from 'axios';
import CoreScanner from '../components/installer/CoreScanner';
import FeedbackButton from '../components/FeedbackButton.vue';
import ConfirmAction from '../components/ConfirmAction.vue';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import * as dateMath from 'date-arithmetic';

export default {
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    PlusIcon,
    MailIcon,
    CoreScanner,
    FeedbackButton,
    ConfirmAction,
    ServerResponse,
    AlertBanner,
    showAllSteps: false,
  },
  setup() {
    return {};
  },

  data() {
    return {
      steps: [
        {
          number: 0,
          complete: false,
        },
        {
          number: 1,
          complete: false,
        },
        {
          number: 2,
          complete: false,
        },
        {
          number: 3,
          complete: false,
        },
        {
          number: 4,
          complete: false,
        },
        {
          number: 5,
          complete: false,
        },
        {
          number: 6,
          complete: false,
        },
        {
          number: 7,
          complete: false,
        },
        {
          number: 8,
          complete: false,
        },
        {
          number: 9,
          complete: false,
        },
        {
          number: 10,
          complete: false,
        },
        {
          number: 11,
          complete: false,
        },
        {
          number: 12,
          complete: false,
        },
      ],
      current_step: 0,
      gateway: {
        core_gateway_id: null,
        gateway_eui: null,
        gateway_serial: null,
        core_account_name: null,
        product_name: 'core-02-868-4g-gateway',
        hostname: null,
        latitude: null,
        longitude: null,
        sim_eid: null,
        sim_iccid: null,
        live: false,
        tti_gateway_id: null,
        install: false,
        lorawan_network_status: 400,
        last_seen: null,
        tags: [],
      },
      showScanner: false,
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      dateMath,
      gatewayAlreadyInstalled: false,
      confirmRemoveGateway: false,
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      simUserMessage: null,
      eid_digits: '',
      serverResponse: '',
      showAllSteps: false,
      gatewaytest: {
        gateway_eui: null,
        gateway_serial: null,
        core_account_name: null,
        sim_eid: null,
        sim_iccid: null,
        tti_gateway_id: null,
        install: false,
        lorawan_network_status: null,
        last_seen: null,
        pass_connection_test: null,
      },
      lastSeenInWords: null,
    };
  },
  methods: {
    getConnectedYouURL() {
      window.open(
        'https://console.connectedyou.io/profiles/' + this.gateway.sim_iccid,
        '_blank',
        'noreferrer'
      );
    },
    getRemoteITURL() {
      window.open(
        'https://app.remote.it/#/devices?' + this.gateway.hostname,
        '_blank',
        'noreferrer'
      );
    },
    moveToStep(step_number) {
      this.current_step = step_number;
      for (let step of this.steps) {
        if (step.number < step_number) {
          step.complete = true;
        } else {
          step.complete = false;
        }
      }
      if (step_number == 1) {
        this.clearEUI();
      }
    },
    loadScanner() {
      this.showScanner = true;
      console.log(
        'loadScanner is called. this.showScanner == ' + this.showScanner
      );
    },
    scannerButtonClick(event) {
      console.log('scannerButtonClick event: ' + event);
      this.showScanner = false;
    },
    // getTimeDiffInMins() {
    //   console.log('this.gateway.last_seen:' + this.gateway.last_seen);

    //   return dateMath.diff(
    //     new Date(this.gateway.last_seen),
    //     new Date(),
    //     'minutes',
    //     false
    //   );
    // },
    getTimeDiffInWords(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = null;

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    clearEUI() {
      this.gateway.gateway_eui = null;
      this.gateway.gateway_serial = null;

      this.gatewaytest.gateway_eui = null;
      this.gatewaytest.gateway_serial = null;
      this.gatewaytest.core_account_name = null;
      this.gatewaytest.sim_eid = null;
      this.gatewaytest.sim_iccid = null;
      this.gatewaytest.tti_gateway_id = null;
      this.gatewaytest.install = false;
      this.gatewaytest.lorawan_network_status = null;
      this.gatewaytest.last_seen = null;
      this.gatewaytest.pass_connection_test = null;
    },
    onDecode(decodedString) {
      if (decodedString.length > 0) {
        this.decodedString = decodedString;
        this.showScanner = false;

        axios
          .get('/gateways?gateway_eui=' + this.decodedString)
          .then((res) => {
            if (res.data.count > 0) {
              this.gateway = res.data.data[0];
              console.log('gateway_eui: ' + this.gateway.gateway_eui);

              if (this.gateway.live) {
                this.gatewayAlreadyInstalled = true;
              }
            } else {
              //There is no gateway currently on the system - that's ok! Let's create one here and then move forward
              this.gateway.gateway_eui = this.decodedString;
              //Let's work out the hostname from the eui
              //It's normally the last 6 digits of the string
              let hostname = this.decodedString.substr(
                this.decodedString.length - 6
              );
              console.log('hostname: ' + 'dragino-' + hostname.toLowerCase());
              this.gateway.hostname = 'dragino-' + hostname.toLowerCase();

              axios
                .post('/gateways', this.gateway)
                .then((res) => {
                  console.log('successfully added gateway');
                  this.gateway = res.data.data;
                  console.log(this.gateway);
                })
                .catch((err) => {
                  console.log('Hit error on axios request');
                  console.log(err.response);
                });
            }
          })
          .catch((err) => {});
      }
    },
    updateGateway() {
      this.submitButtonState = 'loading';

      this.gateway.core_account_name = this.$store.state.account.name;
      this.gateway.core_gateway_id = this.gateway.gateway_eui.toLowerCase();
      this.gateway.install = true;
      this.gateway.live = true;
      console.log('Updating gateway to make it live.');
      console.log('iccid: ' + this.gateway.sim_iccid);

      axios
        .put('/gateways/' + this.gateway._id, this.gateway)
        .then((res) => {
          this.submitButtonState = 'success';

          //Let's now replace our existing Gateway Object with our brand new one (including the correct TTI info)
          this.gateway = res.data.data;
          setTimeout(() => {
            (this.submitButtonState = 'none'), this.moveToStep(4);
          }, 1000);
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },

    removeGateway(userConfirmed) {
      console.log('removeDevice() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveGateway = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        //We will only ever be updating the gateway, not actually deleting it
        this.gateway.install = false;
        axios
          .put('/gateways/' + this.gateway._id, this.gateway)
          .then((res) => {
            this.serverResponse = JSON.stringify(res, null, 2);
            this.raiseSuccessAlert('Successfully removed your gateway');
            this.deleteButtonState = 'success';
            this.gateway = res.data.data;

            //We do want to clear the SIM card information however
            this.gateway.sim_eid = '';
            this.gateway.sim_iccid = '';
            this.gateway.sim_provider = '';
            setTimeout(() => {
              (this.deleteButtonState = 'none'),
                (this.gatewayAlreadyInstalled = false);
            }, 2000);
          })
          .catch((err) => {
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.raiseErrorAlert(err);
            this.deleteButtonState = 'error';
            setTimeout(() => {
              this.deleteButtonState = 'none';
            }, 5000);
          });
      }
    },

    findSimInfo() {
      console.log('findSimInfo called');
      axios
        .get('/simcards?eid=' + this.eid_digits)
        .then((res) => {
          if (res.data.count < 1) {
            this.simUserMessage =
              'No SIM card found. Check your digits and try again. Please email don@corethings.io with issues';
          } else if (res.data.count > 1) {
            this.simUserMessage =
              'Multiple SIM cards found with these digits. Please use more digits to refine your search';
          } else {
            let simcard = res.data.data[0];
            this.gateway.sim_eid = simcard.eid;
            this.gateway.sim_iccid = simcard.iccid;
            this.gateway.sim_provider = simcard.provider;
            this.simUserMessage = '';
          }
        })
        .catch((err) => {
          this.simUserMessage = err;
        });
    },

    refresh() {
      this.showLoading = true;

      axios
        .get(
          process.env.VUE_APP_TTI_GATEWAY_URL +
            this.gateway.tti_gateway_id +
            process.env.VUE_APP_TTI_GATEWAY_REQUEST,
          {
            headers: {
              authorization: `Bearer ${process.env.VUE_APP_TTI_GATEWAY_AUTHORIZATION}`,
            },
          }
        )
        .then((res) => {
          // let previous_last_seen_date = null;
          // let now = new Date();

          // if (this.gateway.last_seen != null) {
          //   previous_last_seen_date = new Date(this.gateway.last_seen);
          // }

          if (res.data.last_status_received_at != null) {
            this.gateway.last_seen = res.data.last_status_received_at;
          }

          //If the previous_last_seen_date is null, then we are installing this for the first time
          // console.log('previous_last_seen_date: ' + previous_last_seen_date);
          // if (previous_last_seen_date != null) {
          //   console.log(
          //     'previous_last_seen_date.getMonth(): ' +
          //       previous_last_seen_date.getMonth()
          //   );
          // }
          // console.log('now.getMonth(): ' + now.getMonth());

          //Let's update the database with the latest inforamtion
          //this.updateGateway();

          //Let's record the test result
          this.gatewaytest.gateway_eui = this.gateway.gateway_eui;
          this.gatewaytest.gateway_serial = this.gateway.gateway_serial;
          this.gatewaytest.core_account_name = this.gateway.core_account_name;
          this.gatewaytest.sim_eid = this.gateway.sim_eid;
          this.gatewaytest.sim_iccid = this.gateway.sim_iccid;
          this.gatewaytest.tti_gateway_id = this.gateway.tti_gateway_id;
          this.gatewaytest.install = this.gateway.install;
          this.gatewaytest.lorawan_network_status =
            this.gateway.lorawan_network_status;
          this.gatewaytest.last_seen = this.gateway.last_seen;
          this.gatewaytest.pass_connection_test =
            this.gateway.pass_connection_test;

          let mins_since_last_seen = dateMath.diff(
            new Date(this.gateway.last_seen),
            new Date(),
            'minutes',
            false
          );

          if (mins_since_last_seen < 5) {
            this.gatewaytest.pass_connection_test = true;
          } else {
            this.gatewaytest.pass_connection_test = false;
          }

          this.lastSeenInWords = this.getTimeDiffInWords(mins_since_last_seen);

          //Let's update the database with the new test record
          axios
            .post('/gatewaytests', this.gatewaytest)
            .then()
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
            });

          setTimeout(() => {
            // this.showLoading = false;
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            // this.showLoading = false;
          }, 500);
        });
    },
  },
};
</script>
