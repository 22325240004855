<template>
  <!-- <div v-if="core_things_user == 'checking'">
</div> -->

  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <a href="/">
              <div class="flex-shrink-0 flex items-center px-4 mb-5">
                <img
                  class="h-14 w-auto"
                  src="./assets/corethings_dark_theme_1200_thick_tailwind.png"
                  alt="CoreThings"
                /></div
            ></a>
            <!-- <div>Dropdown</div> -->
            <Menu
              as="div"
              class="relative inline-block text-left h-16 w-auto bg-gray-800"
            >
              <div>
                <MenuButton
                  class="inline-flex py-4 w-full rounded-md border border-gray-800 shadow-sm px-4 py-2 bg-gray-800 text-medium font-sm text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:offset-gray-800"
                >
                  <OfficeBuildingIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  <p class="px-4">{{ $store.state.account.name }}</p>
                  <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                >
                  <MenuItem
                    v-for="account in accountList"
                    :key="account.name"
                    v-on:click="
                      changeSessionAccount(account);
                      closeSideBar();
                    "
                    v-slot="{ active }"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      ]"
                    >
                      <OfficeBuildingIcon
                        class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {{ account.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <!-- start of navigation - mobile -->
            <div class="flex-1 flex flex-col overflow-y-auto">
              <nav class="flex-1 px-2 py-0 bg-gray-800 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  v-on:click="
                    makeItemCurrent(item);
                    closeSideBar();
                  "
                  :class="[
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}</router-link
                >
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <div class="ml-3 relative" v-if="$auth.isAuthenticated.value">
                <div class="flex items-center">
                  <div>
                    <img
                      class="inline-block h-10 w-10 rounded-full"
                      :src="$auth.user.value.picture"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-white">
                      {{ $auth.user.value.name }}
                    </p>
                    <router-link v-on:click="logout" :to="logout"
                      ><p
                        class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
                      >
                        Logout
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64 bg-gray-800">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <a href="/">
          <div class="flex-shrink-0 flex items-center px-4 mb-0 mt-3 ">
            <img
              class="h-14 w-auto"
              src="./assets/corethings_dark_theme_1200_thick_tailwind.png"
              alt="CoreThings"
            /></div
        ></a>
        <Menu
          as="div"
          class="relative inline-block text-left h-16 w-auto bg-gray-800"
        >
          <div>
            <MenuButton
              class="inline-flex py-4 w-full rounded-md border border-gray-800 shadow-sm px-4 py-2 bg-gray-800 text-medium font-sm text-gray-300 hover:bg-gray-800 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-800 focus:offset-gray-800"
            >
              <OfficeBuildingIcon
                class="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
              <p class="px-4">{{ $store.state.account.name }}</p>
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              <MenuItem
                v-for="account in accountList"
                :key="account.name"
                v-on:click="
                  changeSessionAccount(account);
                  closeSideBar();
                "
                v-slot="{ active }"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  ]"
                >
                  <OfficeBuildingIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {{ account.name }}
                </a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>

        <div class="flex flex-col h-0 flex-1">
          <!-- start of left nav desktop   -->
          <div class="flex-1 flex flex-col overflow-y-auto">
            <nav class="flex-1 px-2 py-0 bg-gray-800 space-y-1">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                v-on:click="makeItemCurrent(item)"
                :class="[
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
              >
                <component
                  :is="item.icon"
                  :class="[
                    item.current
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}</router-link
              >
            </nav>
          </div>

          <div class="flex-shrink-0 flex bg-gray-700 p-4">
            <div class="ml-3 relative" v-if="$auth.isAuthenticated.value">
              <div class="flex items-center">
                <div>
                  <img
                    class="inline-block h-10 w-10 rounded-full"
                    :src="$auth.user.value.picture"
                    alt=""
                  />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-white">
                    {{ $auth.user.value.name }}
                  </p>
                  <router-link v-on:click="logout" :to="logout"
                    ><p
                      class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
                    >
                      Logout
                    </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="flex-shrink-0 flex h-5 z-20 bg-white ">
        <button
          class="relative  h-10 px-4 mt-4 border-gray-200 text-gray-500 focus:outline-none md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div v-if="!loading" class="py-0">
          <div
            v-if="$store.state.coreThingsUser.is_user"
            class="max-w-7xl mx-auto px-0 sm:px-0 md:px-0 "
          >
            <!-- Replace with your content -->

            <router-view></router-view>

            <!-- Replace with your content -->
          </div>
          <div v-else class="max-w-7xl mx-auto px-0 sm:px-0 md:px-0">
            <div class="bg-white py-16 sm:py-24">
              <div class="relative sm:py-16">
                <div aria-hidden="true" class="hidden sm:block">
                  <div
                    class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"
                  />
                  <svg
                    class="absolute top-8 left-1/2 -ml-3"
                    width="404"
                    height="392"
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          class="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="392"
                      fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                    />
                  </svg>
                </div>
                <div
                  class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
                >
                  <div
                    class="relative rounded-2xl px-6 py-10 bg-gray-900 overflow-hidden shadow-xl sm:px-12 sm:py-20"
                  >
                    <div
                      aria-hidden="true"
                      class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
                    >
                      <svg
                        class="absolute inset-0 h-full w-full"
                        preserveAspectRatio="xMidYMid slice"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 1463 360"
                      >
                        <path
                          class="text-gray-500 text-opacity-40"
                          fill="currentColor"
                          d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                        />
                        <path
                          class="text-gray-700 text-opacity-40"
                          fill="currentColor"
                          d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                        />
                      </svg>
                    </div>
                    <div class="relative">
                      <div class="sm:text-center">
                        <h2
                          class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
                        >
                          Currently in Closed Beta Stage :
                          {{ $store.state.coreThingsUser.is_user }}
                        </h2>
                        <p class="mt-6 mx-auto max-w-2xl text-lg text-gray-200">
                          Thank you for registering. We are currently at invite
                          only stage and will contact you when we are ready to
                          start expanding.
                        </p>
                      </div>
                      <div class="mt-20 sm:mx-auto sm:max-w-max ">
                        <div class="mt-4 sm:mt-0 sm:ml-0">
                          <button
                            type="submit"
                            @click="logout"
                            class="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-500 text-base font-medium text-white shadow hover:bg-gray-00 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                          >
                            Sign Out
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex overflow-hidden bg-gray-100 justify-center">
            <div><loading-widget></loading-widget></div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
import concentricCircles from '@/assets/concentric_circles.svg';
import LoadingWidget from './components/LoadingWidget.vue';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  HomeIcon,
  MenuAlt2Icon,
  XIcon,
  WifiIcon,
  LightBulbIcon,
  CameraIcon,
  CogIcon,
  OfficeBuildingIcon,
  ChevronDownIcon,
  ChartBarIcon,
  PhoneOutgoingIcon,
} from '@heroicons/vue/outline';
import { SearchIcon } from '@heroicons/vue/solid';
import AlertBanner from './components/AlertBanner';

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    CogIcon,
    AlertBanner,
    OfficeBuildingIcon,
    ChevronDownIcon,
    InlineSvg,
    concentricCircles,
    ChartBarIcon,
    CameraIcon,
    LoadingWidget,
    PhoneOutgoingIcon,
  },
  setup() {
    const sidebarOpen = ref(false);

    return {
      userNavigation,
      sidebarOpen,
    };
  },
  data() {
    return {
      alert: false,
      accountList: [],
      navigation: [],
      user: null,
      loading: false,
    };
  },
  methods: {
    makeItemCurrent(item) {
      for (let i = 0; i < this.navigation.length; i++) {
        if (this.navigation[i] == item) {
          this.navigation[i].current = true;
        } else this.navigation[i].current = false;
      }
    },
    closeSideBar() {
      this.sidebarOpen = false;
    },
    login() {
      console.log('Forcing Login Redirect flow!');
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$store.commit({
        type: 'updateUserAccount',
        account_name: null,
        account_role: null,
      });
      //this.$store.state.core_things_user = false;
      this.$store.commit({
        type: 'updateCoreThingsUser',
        is_user: false,
      });

      this.$auth.logout({
        // returnTo: window.location.origin,
        returnTo: process.env.VUE_APP_AUTH0_LOGOUT,
      });
    },
    changeSessionAccount(account) {
      this.$store.commit({
        type: 'updateUserAccount',
        account_name: account.name,
        account_role: account.role,
      });
      //Let's do a full refresh of the app
      //location.href = '/';

      this.$router.push('/');

      for (let i = 0; i < this.navigation.length; i++) {
        if (this.navigation[i].name == 'Home') {
          this.navigation[i].current = true;
        } else this.navigation[i].current = false;
      }

      console.log('Setting Menu for account.name: ' + account.name);
      console.log('Setting Menu for account.role: ' + account.role);

      this.setMenuForRole(account.role);
    },
    setMenuForRole(role) {
      console.log('1. Inside setMenuForRole and role = ' + role);
      if (role == 'user' || role == 'admin' || role == 'core_admin') {
        console.log('2. Inside setMenuForRole and role = ' + role);
        this.navigation = [
          {
            name: 'Home',
            href: '/home',
            icon: HomeIcon,
            current: true,
          },
          {
            name: 'Devices',
            href: '/devices',
            icon: LightBulbIcon,
            current: false,
          },
          {
            name: 'Gateways',
            href: '/gateways',
            icon: WifiIcon,
            current: false,
          },
          {
            name: 'Reports',
            href: '/reports',
            icon: ChartBarIcon,
            current: false,
          },
        ];
      }
    },
  },
  created() {
    this.loading = true;
    console.log('App.vue created() called');
    console.log(
      'this.$auth.isAuthenticated.value: ' + this.$auth.isAuthenticated.value
    );

    if (this.$auth.isAuthenticated.value) {
      //User has successfully authenticated with Auth0. We know
      //this user is who they say they are. However, they still
      //may have no authorizations in Corethings. Let's check
      //to see if they are a User within the CoreThings network.

      //If so, let's display the accounts with relevant account_role
      //privileges.

      //If not, then let's display a holding page for the user explaining
      //CoreThings is still in private beta and we'll be in touch

      //Let's escape the "+" in the url to allow testing of specific users
      // don+frespet+install@corethings.io for example

      //Do not make an API call if we already know the User is a core_things_user
      //and this info is stored in the $store.

      //Changing my mind on this one. We should check any time the app itself is reloaded
      //Otherwise, the user maybe added to new accounts and it will not reload this information
      // if (!this.$store.state.coreThingsUser.is_user || this.user == null) {
      axios
        .get(
          '/users?email=' + this.$auth.user.value.email.replaceAll('+', '%2b')
        )
        .then((res) => {
          console.log('res.data.count: ' + res.data.count);

          //If the user is already in CoreThings, then let's display their accounts
          //with associated privileges
          if (res.data.count > 0) {
            //this.$store.state.core_things_user = true;
            this.$store.commit({
              type: 'updateCoreThingsUser',
              is_user: true,
            });
            console.log(
              'this.$store.state.coreThingsUser.is_user: ' +
                this.$store.state.coreThingsUser.is_user
            );
            this.user = res.data.data[0];
            console.log(
              'this.user.core_accounts.length: ' +
                this.user.core_accounts.length
            );
            //Let's set the first account in the list as the session account
            //But only if it is not already set
            //This prevents us from losing the current session account if the
            //user refreshes the page
            console.log(
              'this.$store.state.account.name: ' +
                this.$store.state.account.name
            );
            if (
              this.$store.state.account.name == null ||
              this.$store.state.account.role == null
            ) {
              if (this.user.core_accounts.length > 0) {
                this.$store.commit({
                  type: 'updateUserAccount',
                  account_name: this.user.core_accounts[0].account_name,
                  account_role: this.user.core_accounts[0].account_role,
                });
                console.log(
                  'setting global account: ' + this.$store.state.account.name
                );
              }
            }
            for (let i = 0; i < this.user.core_accounts.length; i++) {
              this.accountList.push({
                name: this.user.core_accounts[i].account_name,
                role: this.user.core_accounts[i].account_role,
              });
            }

            console.log(
              'setting MenuForRole: ' + this.$store.state.account.role
            );
            this.setMenuForRole(this.$store.state.account.role);
          }
          //User is not yet part of the CoreThings network. Let's display a holding page
          //for them explaining we are still in Private Beta
          else {
            //this.$store.state.core_things_user = false;
            this.$store.commit({
              type: 'updateCoreThingsUser',
              is_user: false,
            });
          }

          this.loading = false;
        })
        .catch((err) => {});
      // }

      //User is not authenticated - let's push them back through the Login/SignUp flow
    } else {
      this.$store.commit({
        type: 'updateUserAccount',
        account_name: null,
        account_role: null,
      });
      //this.$store.state.core_things_user = false;
      this.$store.commit({
        type: 'updateCoreThingsUser',
        is_user: false,
      });
      this.login();
    }
  },
  // provide() {
  //   return {};
  // },
};
</script>
