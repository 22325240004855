<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5 "
    v-if="showAllSteps"
  >
    <div>
      <p class="ml-5 mt-2 mb-20 text-md font-medium text-gray-600 ">
        Log onto
        <span class="text-indigo-600">https://eng.corethings.io</span> with your
        username & password and click on
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5 " aria-hidden="true" />
          New Device
        </button>
      </p>
    </div>
  </div>
  <!-- Step 0   -->

  <div class="bg-white shadow overflow-hidden sm:rounded-md m-5">
    <div class="grid grid-cols-2" v-on:click="moveToStep(0)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          0. Attach cable to device
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[0].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[0].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Attach the Program Cable to the back of the device using the
              magnetic connectors
            </div>
            <div>
              <img src="../assets/sensor_wire_1.png" class="h-48 w-auto" />
            </div>
          </div>
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Use the USB to TTL adapter with the following wire configuration
              and attach to the USB port on your computer
              <ul>
                <li>
                  &bull;USB to TTL GND : Dupont black pin
                </li>
                <li>
                  &bull;USB to TTL RXD : Dupont green
                </li>
                <li>
                  &bull;USB to TTL TXD : Dupont white pin
                </li>
              </ul>
            </div>
            <div>
              <img src="../assets/sensor_wire_2.png" class="h-48 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(1)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 0    -->
  <!-- Step 1   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 0 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(1)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          1. Connect to device through the Serial port
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[1].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[1].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Install any Application that allows you to connect with a device
              over the serial port. We recommend
              <a
                class="text-blue-600"
                href="https://www.decisivetactics.com/products/serial/"
                target="_blank"
                >Serial from Decisive Tactics</a
              >
            </div>
            <div>
              <img src="../assets/sensor_serial_1.png" class="h-48 w-auto" />
            </div>
          </div>
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              When opening the port, select the TTL device on the USB port.
              Ensure you select a Baud rate of 9600 for the connection
            </div>
            <div>
              <img src="../assets/sensor_serial_2.png" class="h-48 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(2)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 1    -->

  <!-- Step 2   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 1 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(2)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          2. Select the Device type you are provisioning
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[2].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[2].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
          <div class="text-center mt-0 bg-white ml-10 mr-10 p-10">
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Provision a new Temperature Sensor
            </h3>
            <p class="mt-1 text-sm text-gray-500"></p>
            <div class="mt-6">
              <button
                type="button"
                v-on:click="
                  (deviceBeingProvisioned = 'core-01-868-temp-sensor'),
                    moveToStep(3)
                "
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  height="48px"
                  viewBox="0 0 24 24"
                  width="48px"
                  fill="#ffffff"
                >
                  <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
                  <g>
                    <g>
                      <path
                        d="M15,13V5c0-1.66-1.34-3-3-3S9,3.34,9,5v8c-1.21,0.91-2,2.37-2,4c0,2.76,2.24,5,5,5s5-2.24,5-5C17,15.37,16.21,13.91,15,13 z M11,11V5c0-0.55,0.45-1,1-1s1,0.45,1,1v1h-1v1h1v1v1h-1v1h1v1H11z"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="text-center mt-0 bg-white ml-10 mr-10 p-10">
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Provision a new Door Sensor
            </h3>
            <p class="mt-1 text-sm text-gray-500"></p>
            <div class="mt-6">
              <button
                type="button"
                v-on:click="
                  (deviceBeingProvisioned = 'core-01-868-door-sensor'),
                    moveToStep(3)
                "
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 20 20"
                  height="48px"
                  viewBox="0 0 20 20"
                  width="48px"
                  fill="#ffffff"
                >
                  <g>
                    <rect fill="none" height="20" width="20" />
                    <path
                      d="M15,4v12H5V4H15 M15,3H5C4.45,3,4,3.45,4,4v13h12V4C16,3.45,15.55,3,15,3L15,3z M13,9c-0.55,0-1,0.45-1,1s0.45,1,1,1 s1-0.45,1-1S13.55,9,13,9z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="text-center mt-0 bg-white ml-10 mr-10 p-10">
            <h3 class="mt-2 text-sm font-medium text-gray-900">
              Provision a new Traffic Sensor
            </h3>
            <p class="mt-1 text-sm text-gray-500"></p>
            <div class="mt-6">
              <button
                type="button"
                v-on:click="
                  (deviceBeingProvisioned = 'core-01-868-traffic-sensor'),
                    moveToStep(3)
                "
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="48px"
                  viewBox="0 0 24 24"
                  width="48px"
                  fill="#ffffff"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    d="M13.49 5.48c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-3.6 13.9l1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3c1.3 1.5 3.3 2.5 5.5 2.5v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1l-5.2 2.2v4.7h2v-3.4l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- End of Step 2    -->
  <!-- Step 3   -->

  <!-- Temperature Sensor    -->
  <div
    v-if="deviceBeingProvisioned == 'core-01-868-temp-sensor' || showAllSteps"
  >
    <div
      class="bg-white shadow overflow-hidden sm:rounded-md m-5"
      v-if="current_step > 2 || showAllSteps"
    >
      <div class="grid grid-cols-2" v-on:click="moveToStep(3)">
        <div>
          <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
            3. Configuration for Temperature
          </p>
        </div>
        <div class="justify-self-end mr-10 mt-2 mb-2">
          <CheckCircleIcon
            :class="[
              !steps[3].complete ? 'text-gray-400' : 'text-green-400',
              'h-7 w-7',
            ]"
            aria-hidden="true"
          />
        </div>
      </div>

      <div
        class="flex items-center px-4 py-4 sm:px-6"
        v-if="!steps[3].complete || showAllSteps"
      >
        <div class="min-w-0 flex-1 flex items-center">
          <div class="min-w-0 flex-1 px-4 grid grid-cols-3 gap-10">
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Access the Device
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type <span class="text-red-600 font-sm">123456</span> into the
                  terminal to gain access to the device
                </div>
                <img src="../assets/serial_password.png" class="h-38 w-auto" />
              </div>
            </div>
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Change Device Mode
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type <span class="text-red-600 font-sm">AT+EXT=1</span> to
                  change device to Temperature mode (1 equals Temp)
                </div>
                <img src="../assets/serial_at_ext_1.png" class="h-38 w-auto" />
                <div class="text-xs font-sm text-gray-600 mt-10">
                  Type <span class="text-red-600 font-sm">AT+EXT=?</span> to
                  confirm device mode has been changed to 1
                </div>
                <img
                  src="../assets/serial_at_ext_question_1.png"
                  class="h-38 w-auto"
                />
              </div>
            </div>
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Change Device Transmission Interval
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type
                  <span class="text-red-600 font-sm">AT+TDC=900000</span> (5
                  zeros) to change transmission interval (900,000 milliseconds
                  equals 15 mins)
                </div>
                <img
                  src="../assets/serial_at_tdc_900000.png"
                  class="h-38 w-auto"
                />
                <div class="text-xs font-sm text-gray-600 mt-10">
                  Type <span class="text-red-600 font-sm">AT+TDC=?</span> to
                  confirm device mode has been changed to 900,000 milliseconds
                </div>
                <img
                  src="../assets/serial_at_tdc_question_900000.png"
                  class="h-38 w-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-on:click="moveToStep(4)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Temperature Sensor   -->

  <!-- Door Sensor  -->
  <div
    v-if="deviceBeingProvisioned == 'core-01-868-door-sensor' || showAllSteps"
  >
    <div
      class="bg-white shadow overflow-hidden sm:rounded-md m-5"
      v-if="current_step > 2 || showAllSteps"
    >
      <div class="grid grid-cols-2" v-on:click="moveToStep(3)">
        <div>
          <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
            3. Configuration for Door
          </p>
        </div>
        <div class="justify-self-end mr-10 mt-2 mb-2">
          <CheckCircleIcon
            :class="[
              !steps[3].complete ? 'text-gray-400' : 'text-green-400',
              'h-7 w-7',
            ]"
            aria-hidden="true"
          />
        </div>
      </div>

      <div
        class="flex items-center px-4 py-4 sm:px-6"
        v-if="!steps[3].complete || showAllSteps"
      >
        <div class="min-w-0 flex-1 flex items-center">
          <div class="min-w-0 flex-1 px-4 grid grid-cols-3 gap-10">
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Access the Device
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type <span class="text-red-600 font-sm">123456</span> into the
                  terminal to gain access to the device
                </div>
                <img src="../assets/serial_password.png" class="h-38 w-auto" />
              </div>
            </div>
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Change Device Mode
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type <span class="text-red-600 font-sm">AT+EXT=4,1</span> to
                  change device to Door mode (4,1 equals Door)
                </div>
                <img
                  src="../assets/serial_at_ext_4_1.png"
                  class="h-38 w-auto"
                />
                <div class="text-xs font-sm text-gray-600 mt-10">
                  Type <span class="text-red-600 font-sm">AT+EXT=?</span> to
                  confirm device mode has been changed to 4,1
                </div>
                <img
                  src="../assets/serial_at_ext_question_4_1.png"
                  class="h-38 w-auto"
                />
              </div>
            </div>
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Change Device Transmission Interval
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type
                  <span class="text-red-600 font-sm">AT+TDC=86400000</span> (5
                  zeros) to change transmission interval (86,400,000
                  milliseconds equals 24 hours)
                </div>
                <img
                  src="../assets/serial_at_tdc_86400000.png"
                  class="h-38 w-auto"
                />
                <div class="text-xs font-sm text-gray-600 mt-10">
                  Type <span class="text-red-600 font-sm">AT+TDC=?</span> to
                  confirm device mode has been changed to 86,400,000
                  milliseconds
                </div>
                <img
                  src="../assets/serial_at_tdc_question_86400000.png"
                  class="h-38 w-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-on:click="moveToStep(4)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Door Sensor --->

  <!-- Traffic Sensor --->
  <div
    v-if="
      deviceBeingProvisioned == 'core-01-868-traffic-sensor' || showAllSteps
    "
  >
    <div
      class="bg-white shadow overflow-hidden sm:rounded-md m-5"
      v-if="current_step > 2 || showAllSteps"
    >
      <div class="grid grid-cols-2" v-on:click="moveToStep(3)">
        <div>
          <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
            3. Configuration for Traffic
          </p>
        </div>
        <div class="justify-self-end mr-10 mt-2 mb-2">
          <CheckCircleIcon
            :class="[
              !steps[3].complete ? 'text-gray-400' : 'text-green-400',
              'h-7 w-7',
            ]"
            aria-hidden="true"
          />
        </div>
      </div>

      <div
        class="flex items-center px-4 py-4 sm:px-6"
        v-if="!steps[3].complete || showAllSteps"
      >
        <div class="min-w-0 flex-1 flex items-center">
          <div class="min-w-0 flex-1 px-4 grid grid-cols-3 gap-10">
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Access the Device
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type <span class="text-red-600 font-sm">123456</span> into the
                  terminal to gain access to the device
                </div>
                <img src="../assets/serial_password.png" class="h-38 w-auto" />
              </div>
            </div>
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Change Device Mode
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type <span class="text-red-600 font-sm">AT+EXT=8,1</span> to
                  change device to Traffic mode (8,1 equals Traffic)
                </div>
                <img
                  src="../assets/serial_at_ext_8_1.png"
                  class="h-38 w-auto"
                />
                <div class="text-xs font-sm text-gray-600 mt-10">
                  Type 'AT+EXT=?' to confirm device mode has been changed to 8,1
                </div>
                <img
                  src="../assets/serial_at_ext_question_8_1.png"
                  class="h-38 w-auto"
                />
              </div>
            </div>
            <div>
              <div>
                <div class="text-sm font-sm text-blue-600 mt-0">
                  Change Device Transmission Interval
                </div>
                <div class="text-xs font-sm text-gray-600 mt-2">
                  Type
                  <span class="text-red-600 font-sm">AT+TDC=3600000</span> (5
                  zeros) to change transmission interval (3,600,000 milliseconds
                  equals 60 mins)
                </div>
                <img
                  src="../assets/serial_at_tdc_3600000.png"
                  class="h-38 w-auto"
                />
                <div class="text-xs font-sm text-gray-600 mt-10">
                  Type <span class="text-red-600 font-sm">AT+TDC=?</span> to
                  confirm device mode has been changed to 3,600,000 milliseconds
                </div>
                <img
                  src="../assets/serial_at_tdc_question_3600000.png"
                  class="h-38 w-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-on:click="moveToStep(4)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Traffic Sensor --->

  <!-- End of Step 3    -->
  <!-- Step 4   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 3 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(4)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          4. Scan Device
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[4].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[4].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4 ">
          <div class="flex items-stretch ml-20 ">
            <button
              type="button"
              class="inline-flex self-center max-w-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              v-on:click="loadScanner"
            >
              Scan
            </button>
          </div>

          <div class="col-span-2  mr-10 ">
            <div class="flex justify-items-stretch mt-0">
              <div
                class="justify-self-start text-sm font-sm text-gray-600 mt-2 mr-2 "
              >
                Device EUI:
              </div>
              <div class="mt-1 rounded-md justify-self-start shadow-sm ">
                <input
                  type="text"
                  name="dev_eui"
                  id="dev_eui"
                  class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                  v-model="device.dev_eui"
                />
              </div>
            </div>
          </div>

          <div v-if="deviceAlreadyInstalled">
            <div class="text-sm mb-2 ml-4">
              Device already installed - Remove device to proceed with a clean
              provision
            </div>

            <div>
              <feedback-button
                v-if="deleteButtonState == 'none'"
                v-on:click="confirmRemoveDevice = true"
                :text="'Remove Device'"
                :state="'none'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'loading'"
                :state="'loading'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'success'"
                :state="'success'"
                :text="'Removed!'"
              ></feedback-button>
              <feedback-button
                v-else-if="deleteButtonState == 'error'"
                :state="'error'"
                :text="'Error!'"
              ></feedback-button>
              <confirm-action
                v-if="confirmRemoveDevice"
                :title_text="'Remove Device'"
                :message_text="
                  'Are you sure you want to Remove this Device? This action cannot be undone!'
                "
                :confirm_button_text="'Remove Device'"
                @userAction="removeDevice($event)"
              ></confirm-action>
            </div>
            <div class="text-xs mt-5 ml-4" v-on:click="moveToStep(8)">
              (Jump to last step)
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-if="device.dev_eui.length > 0 && !deviceAlreadyInstalled">
          <feedback-button
            v-if="submitButtonState == 'none'"
            v-on:click="updateDevice"
            :text="'Next'"
            :state="'none'"
          ></feedback-button>
          <feedback-button
            v-else-if="submitButtonState == 'loading'"
            :state="'loading'"
          ></feedback-button>
          <feedback-button
            v-else-if="submitButtonState == 'success'"
            :state="'success'"
            :text="'Installed'"
          ></feedback-button>
          <feedback-button
            v-else-if="submitButtonState == 'error'"
            :state="'error'"
            :text="'Error!'"
          ></feedback-button>
        </div>
      </div>
    </div>
  </div>

  <!-- End of Step 4    -->
  <!-- Step 5   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 4 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(5)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          5. Test Device
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[5].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-0 sm:px-6"
      v-if="!steps[5].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Press and hold the ACT button for 5 seconds until the solid green
              light starts flashing. Then release. Wait for a red light and then
              a solid green light - then proceed to test
            </div>
            <div>
              <img src="../assets/sensor_test.png" class="h-48 w-auto" />
            </div>
          </div>
          <div>
            <div class="block mt-2 justify-end">
              <div>
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  v-on:click="refresh"
                >
                  Test Device Status
                </button>
              </div>

              <div class="block mt-6" v-if="getTimeDiffInMins() < 5">
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-500"
                  disabled
                >
                  CONNECTION PASS
                </button>
              </div>
              <div class="block mt-6" v-else>
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-500"
                  disabled
                >
                  CONNECTION FAIL
                </button>
              </div>

              <div class="text-xs block mt-2">
                Last Seen
                {{ getTimeDiffInWords() }}
              </div>

              <div class="block mt-6" v-if="device.battery >= 3">
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-500"
                  disabled
                >
                  BATTERY PASS
                </button>
              </div>
              <div class="block mt-6" v-else>
                <button
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-500"
                  disabled
                >
                  BATTERY FAIL
                </button>
              </div>

              <div class="text-xs block mt-2">
                Battery Voltage: {{ device.battery }} V
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          v-if="
            devicetest.pass_connection_test == true &&
              devicetest.pass_battery_test == true
          "
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(6)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <div class="p-3 w-full border" v-if="showScanner">
    <core-scanner
      @decode="onDecode"
      @userAction="scannerButtonClick($event)"
    ></core-scanner>
  </div>

  <!-- End of Step 5    -->

  <!-- Step 6   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 5 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(6)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          6. Remove Device
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[5].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex justify-center px-4 py-4 sm:px-6"
      v-if="!steps[6].complete || showAllSteps"
    >
      <div v-if="device.live">
        <feedback-button
          v-if="deleteButtonState == 'none'"
          v-on:click="confirmRemoveDevice = true"
          :text="'Remove Device'"
          :state="'none'"
        ></feedback-button>
        <feedback-button
          v-else-if="deleteButtonState == 'loading'"
          :state="'loading'"
        ></feedback-button>
        <feedback-button
          v-else-if="deleteButtonState == 'success'"
          :state="'success'"
          :text="'Removed!'"
        ></feedback-button>
        <feedback-button
          v-else-if="deleteButtonState == 'error'"
          :state="'error'"
          :text="'Error!'"
        ></feedback-button>
        <confirm-action
          v-if="confirmRemoveDevice"
          :title_text="'Remove Device'"
          :message_text="
            'Are you sure you want to Remove this Device? This action cannot be undone!'
          "
          :confirm_button_text="'Remove Device'"
          @userAction="removeDevice($event)"
        ></confirm-action>
      </div>
      <div v-else>
        <div>
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            v-on:click="moveToStep(7)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="p-3 w-full border" v-if="showScanner">
    <core-scanner
      @decode="onDecode"
      @userAction="scannerButtonClick($event)"
    ></core-scanner>
  </div>

  <!-- End of Step 6    -->

  <!-- Step 7   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 6 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(7)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          7. Turn off the Device
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[1].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[7].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Press the ACT button 5 times until you get a solid red light. This
              indicates the device has been successfully turned off.
            </div>
            <div>
              <img src="../assets/sensor_test.png" class="h-48 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(8)"
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 7    -->

  <!-- Step 8   -->

  <div
    class="bg-white shadow overflow-hidden sm:rounded-md m-5"
    v-if="current_step > 7 || showAllSteps"
  >
    <div class="grid grid-cols-2" v-on:click="moveToStep(8)">
      <div>
        <p class="ml-5 mt-2 text-md font-medium text-indigo-600 ">
          8. Label & package the Device
        </p>
      </div>
      <div class="justify-self-end mr-10 mt-2 mb-2">
        <CheckCircleIcon
          :class="[
            !steps[1].complete ? 'text-gray-400' : 'text-green-400',
            'h-7 w-7',
          ]"
          aria-hidden="true"
        />
      </div>
    </div>

    <div
      class="flex items-center px-4 py-4 sm:px-6"
      v-if="!steps[8].complete || showAllSteps"
    >
      <div class="min-w-0 flex-1 flex items-center">
        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Attach the relevant icon sticker on the front left side of the
              device (depending on which type of device you are configuring)
            </div>
            <div>
              <img src="../assets/device_label_5.png" class="h-48 w-auto" />
              <p class="text-xs font-sm text-gray-600 mt-5 mb-5 mr-5">
                ... and the device part number on the right
              </p>
              <img src="../assets/device_label_2.png" class="h-48 w-auto" />
            </div>
          </div>
          <div>
            <div class="text-xs font-sm text-gray-600 mt-2 mb-5 mr-5">
              Place the correct sensor probe in the box next to the configured
              device
            </div>
            <div>
              <img src="../assets/device_label_3.png" class="h-48 w-auto" />
              <p class="text-xs font-sm text-gray-600 mt-5 mb-5 mr-5">
                ... and the box accordingly, with the icon sticker on top and
                the part number on the front
              </p>
              <img src="../assets/device_label_4.png" class="h-48 w-auto" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          v-on:click="moveToStep(0)"
        >
          Done!
        </button>
      </div>
    </div>
  </div>

  <!-- End of Step 8    -->

  <server-response :response="serverResponse"></server-response>
  <p v-if="!showAllSteps" v-on:click="showAllSteps = true">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#D1D5DB"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </p>
  <p v-if="showAllSteps" v-on:click="showAllSteps = false">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#D1D5DB"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </p>
</template>

<script>
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  PlusIcon,
} from '@heroicons/vue/solid';
import axios from 'axios';
import CoreScanner from '../components/installer/CoreScanner';
import FeedbackButton from '../components/FeedbackButton.vue';
import ConfirmAction from '../components/ConfirmAction.vue';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import * as dateMath from 'date-arithmetic';

export default {
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    CoreScanner,
    FeedbackButton,
    ConfirmAction,
    ServerResponse,
    AlertBanner,
    PlusIcon,
  },
  setup() {
    return {};
  },

  data() {
    return {
      steps: [
        {
          number: 0,
          complete: false,
        },
        {
          number: 1,
          complete: false,
        },
        {
          number: 2,
          complete: false,
        },
        {
          number: 3,
          complete: false,
        },
        {
          number: 4,
          complete: false,
        },
        {
          number: 5,
          complete: false,
        },
        {
          number: 6,
          complete: false,
        },
        {
          number: 7,
          complete: false,
        },
        {
          number: 8,
          complete: false,
        },
      ],
      current_step: 0,
      device: {
        core_device_id: '',
        dev_eui: '',
        app_key: '',
        join_eui: '',
        core_account_name: '',
        battery: null,
        last_seen: null,
        install: true,
        live: true,
      },
      devicetest: {
        dev_eui: null,
        product_name: null,
        temperature_internal: null,
        temperature_probe: null,
        humidity: null,
        counter: null,
        battery: null,
        door_open: null,
        last_seen: null,
        pass_connection_test: null,
        pass_battery_test: null,
      },

      showScanner: false,
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      dateMath,
      deviceAlreadyInstalled: false,
      confirmRemoveDevice: false,
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      simUserMessage: null,
      eid_digits: '',
      serverResponse: '',
      deviceBeingProvisioned: '',
      showAllSteps: false,
    };
  },
  methods: {
    moveToStep(step_number) {
      this.current_step = step_number;
      for (let step of this.steps) {
        if (step.number < step_number) {
          step.complete = true;
        } else {
          step.complete = false;
        }
      }
      if (step_number == 1) {
        this.clearEUI();
      }
    },
    loadScanner() {
      this.showScanner = true;
      console.log(
        'loadScanner is called. this.showScanner == ' + this.showScanner
      );
    },
    scannerButtonClick(event) {
      console.log('scannerButtonClick event: ' + event);
      this.showScanner = false;
    },
    getTimeDiffInMins() {
      console.log('this.device.last_seen:' + this.device.last_seen);

      return dateMath.diff(
        new Date(this.device.last_seen),
        new Date(),
        'minutes',
        false
      );
    },
    getVoltage() {
      return this.device.battery;
    },

    getTimeDiffInWords() {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let time_diff = this.getTimeDiffInMins();

      let returnLastSeen = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    clearEUI() {
      this.device.dev_eui = '';
      this.device.join_eui = '';
      this.device.app_key = '';

      this.devicetest.pass_connection_test = null;
      this.devicetest.pass_battery_test = null;
      this.devicetest.temperature_internal = null;
      this.devicetest.temperature_probe = null;
      this.devicetest.counter = null;
      this.devicetest.battery = null;
      this.devicetest.door_open = null;

      // temperature_internal: null,
      //   temperature_probe: null,
      //   humidity: null,
      //   counter: null,
      //   battery: null,
      //   door_open: null,
    },
    onDecode(decodedString) {
      if (decodedString.length > 0) {
        this.decodedString = decodedString;
        this.showScanner = false;

        axios
          .get('/devices?dev_eui=' + this.decodedString)
          .then((res) => {
            if (res.data.count > 0) {
              this.device = res.data.data[0];
              console.log('dev_eui: ' + this.device.dev_eui);

              if (this.device.install) {
                this.deviceAlreadyInstalled = true;
              } else {
                this.deviceAlreadyInstalled = false;
              }
            } else {
              //TODO
            }
          })
          .catch((err) => {});
      }
    },

    updateDevice() {
      this.submitButtonState = 'loading';

      this.device.core_account_name = this.$store.state.account.name;
      this.device.core_device_id = this.device.dev_eui.toLowerCase();
      this.device.install = true;
      this.device.live = true;
      this.device.product_name = this.deviceBeingProvisioned;
      console.log('Updating device to make it live.');

      axios
        .put('/devices/' + this.device._id, this.device)
        .then((res) => {
          this.submitButtonState = 'success';

          //Let's now replace our existing Device Object with our brand new one
          this.device = res.data.data;
          console.log('New Device post install: ');
          console.log(this.device);
          setTimeout(() => {
            (this.submitButtonState = 'none'), this.moveToStep(5);
          }, 1000);
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },

    removeDevice(userConfirmed) {
      console.log('removeDevice() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveDevice = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        //We will only ever be updating the device, not actually deleting it
        this.device.install = false;
        axios
          .put('/devices/' + this.device._id, this.device)
          .then((res) => {
            this.serverResponse = JSON.stringify(res, null, 2);
            this.raiseSuccessAlert('Successfully removed your device');
            this.deleteButtonState = 'success';
            this.device = res.data.data;
            console.log('Removed Device. Returned Device: ');
            console.log(res.data.data);

            setTimeout(() => {
              (this.deleteButtonState = 'none'),
                (this.deviceAlreadyInstalled = false);
            }, 2000);
          })
          .catch((err) => {
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.raiseErrorAlert(err);
            this.deleteButtonState = 'error';
            setTimeout(() => {
              this.deleteButtonState = 'none';
            }, 5000);
          });
      }
    },

    refresh() {
      this.showLoading = true;
      console.log('refresh() called');

      axios
        .get('/devices/' + this.device._id)
        .then((res) => {
          console.log('After calling devices api');
          console.log(res.data);
          this.device = res.data.data;

          // Let's record the Test Result
          this.devicetest.dev_eui = this.device.dev_eui;
          this.devicetest.product_name = this.device.product_name;
          this.devicetest.temperature_internal = this.device.temperature_internal;
          this.devicetest.temperature_probe = this.device.temperature_probe;
          this.devicetest.humidity = this.device.humidity;
          this.devicetest.counter = this.device.counter;
          this.devicetest.battery = this.device.battery;
          this.devicetest.door_open = this.device.door_open;
          this.devicetest.last_seen = this.device.last_seen;

          //Let's force the results to fail
          this.devicetest.pass_battery_test = false;
          this.devicetest.pass_connection_test = false;

          let mins_since_last_seen = dateMath.diff(
            new Date(this.device.last_seen),
            new Date(),
            'minutes',
            false
          );

          if (mins_since_last_seen < 5) {
            this.devicetest.pass_connection_test = true;
          } else {
            this.devicetest.pass_connection_test = false;
          }

          if (this.device.battery >= 3) {
            this.devicetest.pass_battery_test = true;
          } else {
            this.devicetest.pass_battery_test = false;
          }

          console.log('Now updating the functional test results:');
          console.log(this.devicetest);

          //Let's update the database with the new test record
          axios
            .post('/devicetests', this.devicetest)
            .then()
            .catch((err) => {
              this.raiseErrorAlert(err);
              this.serverResponse = JSON.stringify(err.response, null, 2);
            });

          this.serverResponse = JSON.stringify(res, null, 2);
        })

        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
  },
};
</script>
