<template>
  <div class="grid grid-cols-2 bg-gray-100">
    <div class="text-center mt-20 bg-white ml-10 mr-10 p-10">
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Provision a new Gateway
      </h3>
      <p class="mt-1 text-sm text-gray-500"></p>
      <div class="mt-6">
        <button
          type="button"
          v-on:click="provisionNewGateway"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Gateway
        </button>
      </div>
    </div>
    <div class="text-center  mt-20 bg-white ml-10 mr-10 p-10">
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Provision a New Device
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Ensure you already have a Gateway live on the coreThings network
      </p>
      <div class="mt-6">
        <button
          type="button"
          v-on:click="provisionNewDevice"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Device
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from '@heroicons/vue/solid';

export default {
  setup() {},
  components: {
    PlusIcon,
  },
  methods: {
    provisionNewGateway() {
      this.$router.push('/new-gateway');
    },
    provisionNewDevice() {
      this.$router.push('/new-device');
    },
  },
};
</script>
