Copy code
<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="relative rounded-none"
    v-bind:class="{
      'bg-red-500': $store.state.userAlert.alert_type == 'error',
      'bg-indigo-100': $store.state.userAlert.alert_type == 'success',
    }"
  >
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
        <p
          class="text-sm leading-4 font-medium"
          v-bind:class="{
            'text-white': $store.state.userAlert.alert_type == 'error',
            'text-indigo-700': $store.state.userAlert.alert_type == 'success',
          }"
        >
          <span class="md:hidden"> {{ $store.state.userAlert.message }} </span>
          <span class="hidden md:inline">
            {{ $store.state.userAlert.message }}
          </span>
        </p>
      </div>
      <div
        class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start"
      >
        <button
          type="button"
          @click="dismiss"
          class="flex p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
          v-bind:class="{
            'hover:bg-red-300': $store.state.userAlert.alert_type == 'error',
            'hover:bg-indigo-200':
              $store.state.userAlert.alert_type == 'success',
          }"
        >
          <span class="sr-only">Dismiss</span>
          <XIcon
            class="h-6 w-6"
            v-bind:class="{
              'text-indigo-700': $store.state.userAlert.alert_type == 'success',
              'text-white': $store.state.userAlert.alert_type == 'error',
            }"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline';

export default {
  /*
  props: {
    message: {
      required: true,
      type: String,
    },
    alert_type: {
      required: true,
      type: String,
    },
  },
  */

  components: { XIcon },
  data() {},
  methods: {
    dismiss() {
      console.log('dismiss clicked');
      this.$store.commit({
        type: 'updateUserAlert',
        visible: false,
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: false,
      });
      console.log('Timeout Ended');
    }, 5000);
  },
};
</script>
