<template>
  <div
    class="bg-white shadow overflow-hidden sm:rounded-lg mt-5 mb-5 items-center "
  >
    <div class="px-4 py-5 sm:px-6 ">
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Scan QR code on either the device/gateway to add/rename on the
        CoreThings network
      </p>
    </div>
    <!-- <div>$store.state.account: {{ this.$store.state.account }}</div> -->
    <!-- <div>core_account_name: {{ core_account_name }}</div> -->
    <div class="p-3 w-full border" v-if="showScanner">
      <core-scanner @decode="onDecode"></core-scanner>
    </div>
    <div class="p-3 content-center " v-else>
      <div class="mt-1 mb-1 text-lg text-gray-500 text-center">
        {{ decodedString }}
      </div>
      <div class="mt-10 mb-10 text-center">
        <button
          type="button"
          v-on:click="loadScanner"
          class="content-center px-6 py-3 border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Scan again
        </button>
      </div>

      <div class="rounded-md bg-red-50 p-4" v-if="!proceedWithInstall">
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">
              {{ decodedString }}
            </h3>
            <div class="mt-2 text-sm text-red-700">
              <ul class="list-disc pl-5 space-y-1">
                <li>
                  {{ message_from_corethings }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="rounded-md bg-green-50 p-4" v-else>
        <div class="flex">
          <div class="flex-shrink-0">
            <CheckCircleIcon
              class="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-green-800">
              {{ message_from_corethings }}
            </p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button
                type="button"
                class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
              >
                <span class="sr-only">Dismiss</span>
                <XIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="!showScanner && proceedWithInstall"
    class="space-y-8 divide-y divide-gray-200"
  >
    <div
      class="space-y-8 divide-y divide-gray-200 sm:space-y-5"
      v-if="isDevice"
    >
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Device Info
          </h3>
        </div>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Data Stream Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <div class="max-w-lg flex rounded-md shadow-sm">
              <input
                type="text"
                name="core_device_id"
                id="core_device_id"
                v-model="device.core_device_id"
                v-on:input="validateCoreName"
                class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
              />
            </div>
            <p v-if="coreNameValidationError" class="mt-2 text-xs text-red-600">
              Name can only contain lowercase letters, numbers and dashes(-)
            </p>
          </div>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div
            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <label
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Use Current Location
            </label>

            <div class="mt-1 flex rounded-md shadow-sm">
              <span
                :class="[
                  storeCurrentLocation ? 'text-gray-700' : 'text-gray-200',
                  'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <input
                type="text"
                v-model="geoMessage"
                disabled
                :class="[
                  storeCurrentLocation ? 'text-gray-700' : 'text-gray-200',
                  'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                ]"
              />
            </div>

            <div class="mt-2 sm:col-span-1 align-middle justify-self-center">
              <Switch
                v-model="storeCurrentLocation"
                :class="[
                  storeCurrentLocation ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                ]"
              >
                <span class="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  :class="[
                    storeCurrentLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                  ]"
                />
              </Switch>
            </div>
          </div>

          <div
            class="mt-2 sm:col-span-3 align-middle justify-self-center border"
          >
            <GoogleMap
              api-key="AIzaSyBYinweZPhhopfi2ATYK7KhMigtAI4YbAI"
              style="width: 100%; height: 500px"
              :center="center"
              :zoom="15"
              v-if="showMap && storeCurrentLocation"
            >
              <Marker :options="{ position: center }" />
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>

    <div
      class="space-y-8 divide-y divide-gray-200 sm:space-y-5"
      v-else-if="isGateway"
    >
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Gateway Info
          </h3>
          <!-- <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{ message_from_corethings }}
          </p> -->
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div
            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <label
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Gateway ID
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-lg flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="core_gateway_id"
                  id="core_gateway_id"
                  v-model="gateway.core_gateway_id"
                  v-on:input="validateCoreName"
                  :class="[
                    coreNameValidationError
                      ? 'focus:ring-red-500 focus:border-red-500'
                      : 'focus:ring-indigo-500 focus:border-indigo-500',
                    'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                  ]"
                  class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                />
              </div>
              <p
                v-if="coreNameValidationError"
                class="mt-2 text-xs text-red-600 "
              >
                Name can only contain lowercase letters, numbers and dashes(-)
              </p>
            </div>
          </div>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div
            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <label
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Use Current Location
            </label>

            <div class="mt-1 flex rounded-md shadow-sm">
              <span
                :class="[
                  storeCurrentLocation ? 'text-gray-700' : 'text-gray-200',
                  'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <input
                type="text"
                v-model="geoMessage"
                disabled
                :class="[
                  storeCurrentLocation ? 'text-gray-700' : 'text-gray-200',
                  'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                ]"
              />
            </div>

            <div class="mt-2 sm:col-span-1 align-middle justify-self-center">
              <Switch
                v-model="storeCurrentLocation"
                :class="[
                  storeCurrentLocation ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                ]"
              >
                <span class="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  :class="[
                    storeCurrentLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                  ]"
                />
              </Switch>
            </div>
          </div>

          <div
            class="mt-2 sm:col-span-3 align-middle justify-self-center border"
          >
            <GoogleMap
              api-key="AIzaSyBYinweZPhhopfi2ATYK7KhMigtAI4YbAI"
              style="width: 100%; height: 500px"
              :center="center"
              :zoom="15"
              v-if="showMap && storeCurrentLocation"
            >
              <Marker :options="{ position: center }" />
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button
          type="button"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <div v-if="isDevice">
          <div v-if="isNew">
            <feedback-button
              v-if="submitButtonState == 'none' && !formValidationError"
              v-on:click="installDevice"
              :text="'Install Device'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error' || formValidationError"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>
          </div>
          <div v-else>
            <feedback-button
              v-if="submitButtonState == 'none' && !formValidationError"
              v-on:click="updateDevice"
              :text="'Update Device'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error' || formValidationError"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>
          </div>
        </div>
        <div v-else>
          <div v-if="isNew">
            <feedback-button
              v-if="submitButtonState == 'none' && !formValidationError"
              v-on:click="installGateway"
              :text="'Install Gateway'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error' || formValidationError"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>
          </div>
          <div v-else>
            <feedback-button
              v-if="submitButtonState == 'none' && !formValidationError"
              v-on:click="updateGateway"
              :text="'Update Gateway'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error' || formValidationError"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>
          </div>
          <!-- <button
            v-if="isNew"
            v-on:click="installGateway"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Install Gateway
          </button>
          <button
            v-else
            :disabled="formValidationError == true"
            v-on:click="updateGateway"
            :class="[
              formValidationError
                ? 'bg-indigo-200 hover:bg-indigo-200 '
                : 'bg-indigo-600 hover:bg-indigo-700 ',
              'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            ]"
          >
            Update Gateway
          </button> -->
        </div>
      </div>
      <div
        v-if="formValidationError"
        class="flex justify-end mt-3 text-xs text-red-600"
      >
        <p>Please fix form input errors</p>
      </div>
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
</template>
<script>
import axios from 'axios';
import ServerResponse from '../ServerResponse';
import AlertBanner from '../AlertBanner';
import CoreScanner from './CoreScanner';
import { Switch } from '@headlessui/vue';
import { GoogleMap, Marker } from 'vue3-google-map';
import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';
import FeedbackButton from '../../components/FeedbackButton.vue';

export default {
  components: {
    ServerResponse,
    AlertBanner,
    CoreScanner,
    Switch,
    GoogleMap,
    Marker,
    ExclamationIcon,
    XCircleIcon,
    CheckCircleIcon,
    FeedbackButton,
  },
  setup() {},

  data() {
    return {
      message_from_corethings: '',
      device: {
        core_device_id: '',
        dev_eui: '',
        live: true,
        core_account_name: '',
      },
      gateway: {
        core_gateway_id: '',
        gateway_serial: '',
        gateway_eui: '',
        core_account_name: '',
      },
      latitude: 0,
      longitude: 0,
      coreNameValidationError: false,
      formValidationError: false,
      showScanner: true,
      serverResponse: '',
      isNew: true,
      isGateway: false,
      isDevice: false,
      proceedWithInstall: false,
      geoMessage: '',
      storeCurrentLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      decodedString: '',
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
    };
  },
  mounted() {
    if (!navigator.geolocation) {
      this.geoMessage = 'Geolocation is not supported by your browser';
    } else {
      this.geoMessage = 'Locating…';
      navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
    }
  },
  created() {
    console.log('InstallDevice.create() called');
    this.device.core_account_name = this.$store.state.account.name;
    this.gateway.core_account_name = this.$store.state.account.name;
    console.log(
      'this.device.core_account_name: ' + this.device.core_account_name
    );
  },

  methods: {
    onDecode(decodedString) {
      //Let's reset everything just in case we are scanning multiple pieces of equipment
      const coreAccountName = this.$store.state.account.name;
      this.isGateway = false;
      this.isDevice = false;
      this.isNew = true;
      this.proceedWithInstall = false;
      this.message_from_corethings = '';

      // We have simplified this enormously to the following steps
      // (1) Check to see if the device or gateway is in the coreThings DB and
      // (2) whether it's already installed or not
      // (3) If it's already installed, check to see if we have the correct account

      if (decodedString.length > 0) {
        this.decodedString = decodedString;
        this.showScanner = false;

        axios
          .get('/devices?dev_eui=' + this.decodedString)
          .then((res) => {
            // console.log(res);
            if (res.data.count > 0) {
              //We have a device
              this.isDevice = true;
              this.device = res.data.data[0];
              console.log('this.device.install: ' + this.device.install);
              if (this.device.install) {
                //Let's check to see if it belongs to the same account
                if (this.device.core_account_name == coreAccountName) {
                  this.isNew = false;
                  this.proceedWithInstall = true;
                  this.message_from_corethings =
                    'Found existing Device on the network. Proceed with update';
                } else {
                  this.proceedWithInstall = false;
                  this.message_from_corethings =
                    'This Device is registered with a different Account. Please check you have the correct Account selected or contact support@corethings.io';
                }
              } else {
                this.isNew = true;
                this.proceedWithInstall = true;
                this.device.core_account_name = coreAccountName;
                this.message_from_corethings =
                  'Looking good \\o/.  Proceed with new Install';
              }
            } else {
              //Let's check to see if it's a gateway
              this.message_from_corethings = 'checking for gateway ....';
              axios
                .get('/gateways?gateway_eui=' + this.decodedString)
                .then((res) => {
                  if (res.data.count > 0) {
                    //We have a gateway
                    this.isGateway = true;
                    this.gateway = res.data.data[0];
                    //Check to see if the gateway is already installed
                    if (this.gateway.install) {
                      //Let's check to see if it belongs to the same account
                      if (this.gateway.core_account_name == coreAccountName) {
                        this.isNew = false;
                        this.proceedWithInstall = true;
                        this.message_from_corethings =
                          'Found existing Gateway on the network. Proceed with update';
                      } else {
                        this.proceedWithInstall = false;
                        this.message_from_corethings =
                          'This Gateway is registered with a different Account. Please check you have the correct Account selected or contact support@corethings.io';
                      }
                    } else {
                      this.isNew = true;
                      this.proceedWithInstall = true;
                      this.gateway.core_account_name = coreAccountName;
                      this.message_from_corethings =
                        'Looking good \\o/.  Proceed with new Install';
                    }
                  } else {
                    this.proceedWithInstall = false;
                    this.message_from_corethings =
                      'This product is not registered on the coreThings network. Please contact support@corethings.io for further information';
                  }
                })
                .catch((err) => {
                  this.proceedWithInstall = false;
                  this.message_from_corethings = err.getMessage();
                });
            }
          })
          .catch((err) => {
            this.proceedWithInstall = false;
            this.message_from_corethings = err.getMessage();
          });
      }
    },
    validateCoreName(event) {
      // const regex = /^[a-z0-9-]+$/;
      // if (regex.test(event.target.value)) {
      //   this.coreNameValidationError = false;
      //   this.formValidationError = false;
      //   //console.log(event.target.value + ': ' + true);
      // } else {
      //   //console.log(event.target.value + ': ' + false);
      //   this.coreNameValidationError = true;
      //   this.formValidationError = true;
      // }

      let updatedName = event.target.value;
      updatedName = updatedName.toLowerCase();

      const regex = /^[a-z0-9-]+$/;
      if (regex.test(updatedName)) {
        this.coreNameValidationError = false;
        this.formValidationError = false;
      } else {
        this.coreNameValidationError = true;
        this.formValidationError = true;
      }

      this.gateway.core_gateway_id = updatedName;
      this.device.core_device_id = updatedName;
    },
    loadScanner() {
      this.showScanner = true;
      this.message_from_corethings = '';
      this.formValidationError = false;
      this.coreNameValidationError = false;
    },

    geoSuccess(position) {
      this.latitude = position.coords.latitude.toFixed(2);
      this.longitude = position.coords.longitude.toFixed(2);
      this.geoMessage = this.latitude + ', ' + this.longitude;

      this.center.lat = position.coords.latitude;
      this.center.lng = position.coords.longitude;
      this.showMap = true;
    },

    geoError() {
      this.geoMessage = 'Error determining location';
    },
    installDevice() {
      this.submitButtonState = 'loading';

      this.device.install = true;
      this.device.live = true;

      //Let's set the location data on the device
      if (this.storeCurrentLocation) {
        this.device.latitude = this.latitude;
        this.device.longitude = this.longitude;
      }

      //Since these devices are already on the coreThings network, we only ever update them
      //
      axios
        .put('/devices/' + this.device._id, this.device)
        .then((res) => {
          //this.raiseSuccessAlert('Successfully installed your Device!');
          this.submitButtonState = 'success';
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);

          //this.$router.push('/devices/' + res.data.data._id);
          //Due to a bug in vue-router and routes with dynamic components not working with scrollBehavior
          //We're going to use a standard href redirect to move to the top of the page
          location.href = '/devices/' + res.data.data._id;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
          console.log('addDevice() error');
        });
    },
    installGateway() {
      this.submitButtonState = 'loading';
      this.gateway.install = true;
      this.gateway.live = true;
      //Let's set the location data on the device
      if (this.storeCurrentLocation) {
        this.gateway.latitude = this.latitude;
        this.gateway.longitude = this.longitude;
      }

      //Since these devices are already on the coreThings network, we only ever update them
      axios
        .put('/gateways/' + this.gateway._id, this.gateway)
        .then((res) => {
          // this.raiseSuccessAlert('Successfully created your Gateway!');
          this.submitButtonState = 'success';
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);

          //this.$router.push('/devices/' + res.data.data._id);
          //Due to a bug in vue-router and routes with dynamic components not working with scrollBehavior
          //We're going to use a standard href redirect to move to the top of the page
          location.href = '/gateways/' + res.data.data._id;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
          console.log('addGateway() error');
        });
    },
    updateDevice() {
      this.submitButtonState = 'loading';
      //Let's set the location data on the device
      if (this.storeCurrentLocation) {
        this.device.latitude = this.latitude;
        this.device.longitude = this.longitude;
      }
      axios
        .put('/devices/' + this.device._id, this.device)
        .then((res) => {
          //this.raiseSuccessAlert('Successfully updated your Device!');
          this.submitButtonState = 'success';
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          //Due to a bug in vue-router and routes with dynamic components not working with scrollBehavior
          //We're going to use a standard href redirect to move to the top of the page
          location.href = '/devices/' + res.data.data._id;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },
    updateGateway() {
      this.submitButtonState = 'loading';

      //Let's set the location data on the device
      if (this.storeCurrentLocation) {
        this.gateway.latitude = this.latitude;
        this.gateway.longitude = this.longitude;
      }
      axios
        .put('/gateways/' + this.gateway._id, this.gateway)
        .then((res) => {
          //this.raiseSuccessAlert('Successfully updated your Gateway!');
          this.submitButtonState = 'success';
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);

          //Due to a bug in vue-router and routes with dynamic components not working with scrollBehavior
          //We're going to use a standard href redirect to move to the top of the page
          location.href = '/gateways/' + res.data.data._id;
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },
};
</script>
