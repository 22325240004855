<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-5 mb-5  ">
    <div class="px-4 py-5 sm:px-6">
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Scan QR code on the device to get current readings
      </p>
    </div>

    <div class="p-3 w-full" v-if="showScanner">
      <core-scanner @decode="onDecode"></core-scanner>
    </div>
    <div class="p-3 content-center border" v-else>
      <div class="mt-1 mb-1 text-lg text-gray-500 text-center">
        {{ decodedString }}
      </div>
      <div class="mt-10 mb-10 text-center">
        <button
          type="button"
          v-on:click="loadScanner"
          class="content-center px-6 py-3 border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Scan again
        </button>
      </div>
    </div>
  </div>

  <div class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{ message_from_corethings }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
</template>
<script>
import axios from 'axios';
import ServerResponse from '../ServerResponse';
import AlertBanner from '../AlertBanner';
import CoreScanner from './CoreScanner';

export default {
  components: {
    CoreScanner,
    ServerResponse,
    AlertBanner,
  },
  data() {
    return {
      message_from_corethings: '',
      showScanner: true,
      serverResponse: '',
      coreAccountName: '',
      decodedString: '',
    };
  },
  created() {
    console.log('InstallDevice.create() called');
    this.coreAccountName = this.$store.state.account.name;
    console.log('this.coreAccountName: ' + this.coreAccountName);
  },

  methods: {
    onDecode(decodedString) {
      console.log('onDecode: [' + decodedString + ']');
      this.decodedString = decodedString;
      if (decodedString.length > 0) {
        let dev_eui = decodedString;
        this.showScanner = false;

        axios
          .get(
            '/devices?dev_eui=' +
              dev_eui +
              '&core_account_name=' +
              this.coreAccountName
          )
          .then((res) => {
            console.log(res.data);
            this.serverResponse = JSON.stringify(res, null, 2);

            if (res.data.count > 0) {
              let device_id = res.data.data[0]._id;
              this.$router.push('/devices/' + device_id);
            } else {
              //Maybe it's a gateway - let's check this
              console.log(
                'axios request: ' +
                  '/gateways?gateway_eui=' +
                  dev_eui +
                  '&core_account_name=' +
                  this.coreAccountName
              );
              axios
                .get(
                  '/gateways?gateway_eui=' +
                    dev_eui +
                    '&core_account_name=' +
                    this.coreAccountName
                )
                .then((res) => {
                  if (res.data.count > 0) {
                    let gateway_id = res.data.data[0]._id;
                    this.$router.push('/gateways/' + gateway_id);
                  } else {
                    this.message_from_corethings =
                      "Product is not installed on the '" +
                      this.coreAccountName +
                      "' Account";
                    this.serverResponse = JSON.stringify(res, null, 2);
                  }
                })
                .catch((err) => {
                  this.raiseErrorAlert(err);
                  this.serverResponse = JSON.stringify(err.response, null, 2);
                });
            }
          })
          .catch((err) => {
            this.raiseErrorAlert(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
          });
      }
    },

    loadScanner() {
      this.showScanner = true;
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },
};
</script>
