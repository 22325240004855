<template>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">
        Scan
      </h1>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->
      <div>
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>

          <select
            id="tabs"
            name="tabs"
            v-model="tab_route"
            class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          >
            <option
              v-for="tab in tabs"
              :key="tab.name"
              :selected="tab.current"
              :value="tab.href"
              >{{ tab.name }}</option
            >
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <router-link
                v-for="tab in tabs"
                :key="tab.name"
                :to="tab.href"
                :class="[
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm',
                ]"
                :aria-current="tab.current ? 'page' : undefined"
              >
                <component
                  :is="tab.icon"
                  :class="[
                    tab.current
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  ]"
                  aria-hidden="true"
                />
                <span>{{ tab.name }}</span>
              </router-link>
            </nav>
          </div>
        </div>
      </div>
      <div v-if="currentTab === 'installProduct'">
        <install-product></install-product>
      </div>
      <div v-else-if="currentTab === 'checkProduct'">
        <check-product></check-product>
      </div>
      <!-- <div v-else-if="currentTab === 'replaceDevice'">
        <replace-device></replace-device>
      </div> -->

      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
import InstallProduct from '../components/installer/InstallProduct';
import CheckProduct from '../components/installer/CheckProduct';
//import ReplaceProduct from '../components/installer/ReplaceProduct';

import {
  CreditCardIcon,
  OfficeBuildingIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/vue/solid';

const tabs = [
  {
    name: 'installProduct',
    href: '/scan?tab=installProduct',
    icon: UserIcon,
    current: true,
  },
  {
    name: 'checkProduct',
    href: '/scan?tab=checkProduct',
    icon: OfficeBuildingIcon,
    current: false,
  },
  // {
  //   name: 'replaceProduct',
  //   href: '/install?tab=replaceProduct',
  //   icon: UsersIcon,
  //   current: false,
  // },
];

export default {
  setup() {
    return {
      tabs,
    };
  },
  data() {
    return {
      currentTab: 'installProduct',
      tab_route: '',
    };
  },
  components: {
    InstallProduct,
    CheckProduct,
    //ReplaceProduct,
  },
  watch: {
    tab_route(value) {
      this.$router.push(value);
    },
  },
  updated() {
    this.currentTab = this.$route.query.tab;

    for (let i = 0; i < this.tabs.length; i++) {
      if (tabs[i].name == this.currentTab) {
        tabs[i].current = true;
      } else tabs[i].current = false;
    }
  },
  created() {
    console.log('created() called');
    for (let i = 0; i < this.tabs.length; i++) {
      if (tabs[i].name == this.currentTab) {
        tabs[i].current = true;
        this.tab_route = tabs[i].href;
      } else tabs[i].current = false;
    }
  },
};
</script>
