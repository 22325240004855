<template>
  <div class="grid grid-cols-2 bg-white border border-transparent">
    <div class="z-10 h-10 w-full bg-white border-0 border-transparent ml-20">
      <div
        class="relative w-full text-gray-400 border border-transparent focus-within:text-gray-600 ml-0"
      >
        <div
          class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
        >
          <SearchIcon class="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          class="block w-full h-full pl-8 pr-3 py-1 border-transparent text-gray-700 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
          placeholder="Search"
          type="search"
          v-on:input="inputSearch"
        />
      </div>
    </div>
    <Menu
      as="div"
      class="z-30 justify-self-end text-left border border-transparent mr-5"
    >
      <MenuButton
        class="flex w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-sm font-sm text-gray-500 hover:bg-gray-50 focus:outline-none focus:placeholder-none focus:ring-0 focus:border-transparent"
      >
        Filter by Tag
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        >
          <div v-for="tag_group in account_tag_groups" :key="tag_group">
            <div class="px-4 py-3 text-indigo-700 bg-indigo-100">
              <p class="text-sm font-sm truncate">
                {{ tag_group.name }}
              </p>
            </div>
            <div class="py-1">
              <MenuItem v-for="tag in tag_group.tags" :key="tag">
                <div
                  class="bg-white text-gray-700 block px-4 py-2 text-sm"
                  v-on:click="addTagAndSearch(tag)"
                >
                  {{ tag }}
                </div>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
  <div class="grid justify-items-stretch">
    <div class="flex border border-transparent justify-self-end mr-10 mt-1">
      <div
        v-for="tag in search_tags"
        :key="tag"
        v-on:click="removeTagAndSearch(tag)"
        class="flex mt-1 mr-1 px-1 max-w-min border border-transparent text-sm leading-4 rounded-md text-indigo-700 bg-indigo-100"
      >
        <div>
          {{ tag }}
        </div>
        <div><XIcon class="h-3 w-3 ml-1" aria-hidden="true" /></div>
      </div>
    </div>
  </div>

  <div class="py-6 px-6">
    <div class="flex justify-end p-2">
      <router-link :to="'/scan?tab=installProduct'">
        <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Gateway
        </button>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gateway ID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tags
                  </th>
                  <!-- <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gateway EUI
                  </th> -->

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Live
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Seen
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(gateway, gatewayIdx) in gateways"
                  :key="gateway.gateway_eui"
                  :class="gatewayIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex"
                  >
                    <router-link :to="'/gateways/' + gateway._id">{{
                      gateway.core_gateway_id
                    }}</router-link>
                    <div v-if="gateway.hostname">
                      <a
                        :href="
                          'https://app.remote.it/#devices/' + gateway.hostname
                        "
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3 w-3 stroke-current text-purple-600 ml-1"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0"
                          />
                        </svg>
                      </a>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.description }}
                  </td>
                  <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.gateway_eui }}
                  </td> -->
                  <td
                    class="flex px-2 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    <div
                      v-for="tag in gateway.tags"
                      :key="tag"
                      class="mt-1 mr-1 px-1 max-w-min border border-transparent text-xs leading-4 rounded-md text-indigo-700 bg-indigo-100"
                    >
                      {{ tag }}
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ gateway.live }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      getTimeDiff(
                        dateMath.diff(
                          new Date(gateway.last_seen),
                          new Date(),
                          'minutes',
                          false
                        )
                      )
                    }}
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a href="#" class="text-indigo-600 hover:text-indigo-900"
                      >Edit</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <server-response :response="serverResponse"></server-response>
    <!-- /End replace -->
  </div>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import { SearchIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';

export default {
  components: {
    ServerResponse,
    AlertBanner,
    SearchIcon,
    XIcon,
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  data() {
    return {
      gateways: [],
      dateMath,
      serverResponse: '',
      latestSearchString: '',
      account_tag_groups: {},
      search_string: '',
      search_tags: [],
    };
  },
  methods: {
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    makeHTTPRequest(url) {
      axios
        .get(url)
        .then((res) => {
          console.log(res.data);
          this.gateways = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((error) => {
          console.log(error);
          this.serverResponse = JSON.stringify(error, null, 2);
          this.raiseErrorAlert(error);
        });
    },
    addTagAndSearch(tag) {
      this.search_tags.push(tag);
      //console.log('adding search_tag: ' + tag);
      const currentAccountSession = this.$store.state.account.name;
      let url =
        '/gateways?core_account_name=' +
        currentAccountSession +
        '&search_string=' +
        this.latestSearchString;

      for (let tag of this.search_tags) {
        url = url + '&search_tags=' + tag;
      }

      this.makeHTTPRequest(url);
    },
    removeTagAndSearch(tag) {
      const index = this.search_tags.indexOf(tag);
      if (index > -1) {
        this.search_tags.splice(index, 1);
      }
      const currentAccountSession = this.$store.state.account.name;
      let url =
        '/gateways?core_account_name=' +
        currentAccountSession +
        '&search_string=' +
        this.latestSearchString;

      for (let tag of this.search_tags) {
        url = url + '&search_tags=' + tag;
      }

      this.makeHTTPRequest(url);
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },

    inputSearch(event) {
      this.latestSearchString = event.target.value;

      setTimeout(
        function (currentSearchString) {
          //still the same after 2 seconds, then we know the user
          //has stopped typing
          if (currentSearchString == this.latestSearchString) {
            //console.log('Searching: ' + currentSearchString);
            const currentAccountSession = this.$store.state.account.name;
            let url =
              '/gateways?core_account_name=' +
              currentAccountSession +
              '&search_string=' +
              currentSearchString;

            for (let tag of this.search_tags) {
              url = url + '&search_tags=' + tag;
            }

            console.log('url: ' + url);
            axios
              .get(url)
              .then((res) => {
                console.log(res.data);
                this.gateways = res.data.data;
                this.serverResponse = JSON.stringify(res, null, 2);
              })
              .catch((error) => {
                console.log(error);
                this.serverResponse = JSON.stringify(error, null, 2);
                this.raiseErrorAlert(error);
              });
          }
        }.bind(this),
        1000,
        event.target.value
      );
    },
  },

  created() {
    const currentAccountSession = this.$store.state.account.name;
    axios
      .get('/gateways?core_account_name=' + currentAccountSession)
      .then((res) => {
        console.log(res.data);
        this.gateways = res.data.data;
        this.serverResponse = JSON.stringify(res, null, 2);
      })
      .catch((error) => {
        console.log(error);
        this.serverResponse = JSON.stringify(error, null, 2);
        this.raiseErrorAlert(error);
      });

    // Let's get all offical tags for this account
    axios
      .get('/accounts?account_name=' + currentAccountSession)
      .then((res) => {
        let account = res.data.data[0];
        this.account_tag_groups = account.account_tag_groups;
      })
      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });
  },

  unmounted() {
    this.$store.state.userAlert.visible = false;
  },
};
</script>
