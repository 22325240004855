<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
      :open="open"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div>
              <div
                class="mx-auto flex items-center justify-center rounded-full "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:dc="http://purl.org/dc/elements/1.1/"
                  xmlns:cc="http://web.resource.org/cc/"
                  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                  xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                  version="1.1"
                  baseProfile="full"
                  width="160px"
                  height="160px"
                  viewBox="0 0 160 160"
                  preserveAspectRatio="xMidYMid meet"
                  id="svg_document"
                  style="zoom: 1;"
                  visibility="visible"
                >
                  <!-- Created with macSVG - https://macsvg.org/ - https://github.com/dsward2/macsvg/ -->
                  <title id="svg_document_title" visibility="visible">
                    corethings.svg
                  </title>
                  <defs id="svg_document_defs" visibility="visible"></defs>
                  <g id="main_group" visibility="visible"></g>
                  <circle
                    id="core"
                    cx="80px"
                    cy="80px"
                    r="42px"
                    fill="lightgrey"
                    visibility="visible"
                  ></circle>
                  <circle
                    id="trajectory"
                    cx="80px"
                    cy="80px"
                    r="60px"
                    fill="none"
                    stroke="lightgrey"
                    stroke-width="2px"
                    visibility="visible"
                  ></circle>
                  <circle
                    id="thing_loading"
                    cx="80px"
                    cy="20px"
                    r="6px"
                    fill="lightgrey"
                    stroke="none"
                    visibility="visible"
                  ></circle>
                  <circle
                    id="thing_constant"
                    cx="80px"
                    cy="20px"
                    r="6px"
                    fill="lightgrey"
                    stroke="none"
                    visibility="visible"
                  ></circle>
                </svg>
              </div>
            </div>
            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
            >
              <button type="button"></button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
  },
  setup() {
    const open = true;

    return {
      open,
    };
  },
};
</script>
