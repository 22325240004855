<template>
  <div class="py-6 px-6">
    <!-- <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">Device Detail</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"> -->
    <!-- Replace with your content -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Device Details: {{ device.core_device_id }}
        </h3>

        <div class="ml-0 mt-0 ">
          <div class="flex items-center mt-10" v-if="device.live">
            <button
              type="button"
              class=" inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="refresh"
            >
              Refresh
            </button>

            <div
              class="flex-shrink-0 mt-0 ml-10 max-w-2xl text-sm text-gray-500"
            >
              Last Seen
              {{
                getTimeDiff(
                  dateMath.diff(
                    new Date(device.last_seen),
                    new Date(),
                    'minutes',
                    false
                  )
                )
              }}
            </div>
          </div>

          <div v-else class="mt-1 max-w-2xl text-sm text-gray-500">
            Last Seen : Data Stream is turned off
          </div>
        </div>

        <p></p>

        <div class="p-8">
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <data-stream-panel
              v-if="device.capabilities.includes('temperature_internal')"
              :live="device.live"
              :name="'Temperature Internal'"
              :value="device.temperature_internal"
              :unit="'&deg;C'"
            ></data-stream-panel>

            <!-- break in data block  -->
            <data-stream-panel
              v-if="device.capabilities.includes('temperature_probe')"
              :live="device.live"
              :name="'Temperature Probe'"
              :value="device.temperature_probe"
              :unit="'&deg;C'"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('humidity')"
              :live="device.live"
              :name="'Humidity'"
              :value="device.humidity"
              :unit="'%'"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('battery')"
              :live="device.live"
              :name="'Battery'"
              :value="device.battery"
              :unit="'V'"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('door_open')"
              :live="device.live"
              :name="'Door Open'"
              :value="device.door_open"
            ></data-stream-panel>

            <!-- break in data block  -->

            <data-stream-panel
              v-if="device.capabilities.includes('counter')"
              :live="device.live"
              :name="'Traffic'"
              :value="device.counter"
            ></data-stream-panel>

            <!-- break in data block  -->
            <data-stream-panel
              v-if="device.capabilities.includes('eco2')"
              :live="device.live"
              :name="'Effective CO2'"
              :value="device.eco2"
            ></data-stream-panel>

            <!-- break in data block  -->
            <data-stream-panel
              v-if="device.capabilities.includes('tvoc')"
              :live="device.live"
              :name="'Total Volatile Organic Compound'"
              :value="device.tvoc"
            ></data-stream-panel>

            <!-- break in data block  -->
            <data-stream-panel
              v-if="device.capabilities.includes('interrupt')"
              :live="device.live"
              :name="'Interrupt'"
              :value="device.interrupt"
            ></data-stream-panel>

            <!-- break in data block  -->
          </dl>
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Data Stream ID
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="core_device_id"
                      id="core_device_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.core_device_id"
                      v-on:input="validateStreamName"
                    />
                  </div>
                  <p
                    v-if="streamNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Live</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Switch
                  v-model="device.live"
                  :class="[
                    device.live ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  ]"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      device.live ? 'translate-x-5 ' : 'translate-x-0 ',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                    ]"
                  />
                </Switch>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Tags</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    :autocomplete-items="filteredItems"
                    @tags-changed="(newTags) => (tags = newTags)"
                  />
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Device EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="dev_eui"
                      id="dev_eui"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.dev_eui"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Data Stream Description
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <textarea
                      rows="3"
                      name="description"
                      id="description"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.description"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Address</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="address"
                      id="address"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="device.address"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Alerts</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                <div class="flex justify-end p-2 pr-5">
                  <button
                    type="button"
                    v-on:click="addAlert"
                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add Alert
                  </button>
                </div>
                <ul class="border=0 border-gray-200 rounded-md">
                  <!-- Alert list -->

                  <li
                    class="bg-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm border-2 border-white"
                    v-for="(alert, position) in device.alerts"
                    :key="position"
                  >
                    <!-- Start Alert (move to component in the future -->

                    <form class="space-y-8 divide-y divide-gray-200">
                      <div class="space-y-8 divide-y divide-gray-200">
                        <div class="pt-8">
                          <div class="sm:col-span-2 justify-self-center">
                            <p>Active</p>
                            <Switch
                              v-model="alert.active"
                              :class="[
                                alert.active ? 'bg-indigo-600' : 'bg-white',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                              ]"
                            >
                              <span class="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                :class="[
                                  alert.active
                                    ? 'translate-x-5 '
                                    : 'translate-x-0 ',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                                ]"
                              />
                            </Switch>
                          </div>

                          <div
                            class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                          >
                            <div class="sm:col-span-6">
                              <label
                                for="street-address"
                                class="block text-sm font-medium text-gray-700"
                              >
                                Alert Name
                              </label>
                              <div class="mt-1">
                                <input
                                  type="text"
                                  v-model="alert.name"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div class="sm:col-span-2">
                              <div class="mt-0">
                                <label
                                  for="capability"
                                  class="block text-sm font-medium text-gray-700"
                                >
                                  Capability
                                </label>
                                <div class="mt-1">
                                  <select
                                    v-model="alert.capability"
                                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option>temperature_internal</option>
                                    <option>humidity</option>
                                    <option>battery</option>
                                    <option>door_open</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="sm:col-span-2">
                              <div class="mt-0">
                                <label
                                  for="operator"
                                  class="block text-sm font-medium text-gray-700"
                                >
                                  Operator
                                </label>
                                <div class="mt-1">
                                  <select
                                    v-model="alert.operator"
                                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  >
                                    <option>less than</option>
                                    <option>greater than</option>
                                    <option>equal to</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="sm:col-span-2">
                              <label
                                for="zip"
                                class="block text-sm font-medium text-gray-700"
                              >
                                Value
                              </label>
                              <div class="mt-1">
                                <input
                                  type="text"
                                  v-model="alert.value"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                              <p class="ml-1 mt-1 text-xs">
                                For Doors: 1 = open; 0 = closed
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pt-8"
                        >
                          <div class="sm:col-span-6">
                            <h3
                              class="text-lg leading-6 font-medium text-gray-900"
                            >
                              Notification Channels
                            </h3>
                            <p class="mt-1 text-sm text-gray-500">
                              Indicate how you would like to be notified. We
                              integrate with PagerDuty for escalations - please
                              click here for more information.
                            </p>
                          </div>

                          <div class="sm:col-span-4 sm:col-start-1">
                            <div class="mt-0">
                              <label
                                for="pager-duty-escalation-channel"
                                class="block text-sm font-medium text-gray-700"
                              >
                                Select Esclation Channel
                              </label>
                              <div class="mt-1">
                                <select
                                  v-model="alert.escalation_channel"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                  <option
                                    v-for="channel in escalation_channels"
                                    :key="channel.name"
                                    :value="{
                                      provider: channel.provider,
                                      name: channel.name,
                                      api_key: channel.api_key,
                                      routing_key: channel.routing_key,
                                      email_notification:
                                        channel.email_notification,
                                    }"
                                    >[{{ channel.provider }}]
                                    {{ channel.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="pt-5">
                        <div class="flex justify-end">
                          <div class="justify-self-end">
                            <button
                              type="button"
                              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              v-on:click="deleteAlert(position)"
                            >
                              Delete Alert
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <!--  End of Alert component  -->
                  </li>
                </ul>
              </dd>
            </div>

            <!-- Start map component   -->

            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Location
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <span
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                    ]"
                    @click="useCurrentLocation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="geoMessage"
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                    ]"
                  />

                  <div class="ml-4 mt-2">
                    <p class="text-sm text-gray-500 ">
                      <RefreshIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                        @click="refreshLocation"
                      />
                    </p>
                  </div>
                </div>

                <div
                  class="mt-2 sm:col-span-1 align-middle justify-self-center"
                >
                  <Switch
                    v-model="storeLocation"
                    :class="[
                      storeLocation ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        storeLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                      ]"
                    />
                  </Switch>
                </div>
              </div>

              <div
                class="mt-2 sm:col-span-3 align-middle justify-self-center border"
              >
                <GoogleMap
                  :api-key="googleMapsAPIKey"
                  style="width: 100%; height: 500px"
                  :center="center"
                  :zoom="14"
                  v-if="showMap && storeLocation"
                >
                  <Marker
                    :options="{
                      position: center,
                      icon: require('@/assets/green-marker.png'),
                    }"
                  />
                </GoogleMap>
              </div>
            </div>
            <!-- End Map component -->
          </dl>
        </div>

        <div v-if="userAlertBottom.status == 'success'">
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-green-800">
                  {{ userAlertBottom.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <!-- <button
              type="button"
              v-on:click="confirmRemoveDevice = true"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete Device
            </button> -->
            <feedback-button
              v-if="deleteButtonState == 'none'"
              v-on:click="confirmRemoveDevice = true"
              :text="'Delete Device'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'error'"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>

            <confirm-action
              v-if="confirmRemoveDevice"
              :title_text="'Delete Device'"
              :message_text="
                'Are you sure you want to Delete this Device? This action cannot be undone!'
              "
              :confirm_button_text="'Delete Device'"
              @userAction="removeDevice($event)"
            ></confirm-action>

            <feedback-button
              v-if="submitButtonState == 'none'"
              v-on:click="updateDevice"
              :text="'Submit'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error'"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <alert-banner v-if="$store.state.userAlert.visible"></alert-banner>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import DataStreamPanel from '../components/DataStreamPanel.vue';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker } from 'vue3-google-map';
import LoadingWidget from '../components/LoadingWidget.vue';
import FeedbackButton from '../components/FeedbackButton.vue';
import ConfirmAction from '../components/ConfirmAction.vue';
import VueTagsInput from '@sipec/vue3-tags-input';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
    DataStreamPanel,
    LoadingWidget,
    FeedbackButton,
    ConfirmAction,
    VueTagsInput,
  },

  data() {
    return {
      device: {
        capabilities: [{}],
      },
      dateMath,
      serverResponse: '',
      streamNameValidationError: false,
      euiIDValidationError: false,
      userAlertBottom: {
        status: '',
        message: '',
      },

      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      googleMapsAPIKey: '',
      showLoading: false,
      escalation_channels: [],
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      confirmRemoveDevice: false,
      confirmUserAction: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
    };
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },

  methods: {
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },
    validateStreamName(event) {
      //As we are validating, let's force to all lowercase while we're at it
      console.log(event.target.value);
      let updatedName = event.target.value;
      updatedName = updatedName.toLowerCase();

      const regex = /^[a-z0-9-]+$/;
      if (regex.test(updatedName)) {
        this.streamNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.streamNameValidationError = true;
      }

      this.device.core_device_id = updatedName;
    },
    addAlert() {
      this.device.alerts.unshift({
        name: 'New alert name',
        capability: '',
        operator: '',
        value: 0,
        active: false,
        escalation: {
          provider: 'pager duty',
          path: { name: '', key: '' },
        },
      });
    },
    deleteAlert(position) {
      console.log('Deleting Alert from position ' + position);
      this.device.alerts.splice(position, 1);
    },
    updateDevice() {
      this.submitButtonState = 'loading';
      //Let's set the location data on the device
      if (this.storeLocation) {
        this.device.latitude = this.center.lat;
        this.device.longitude = this.center.lng;
      }

      //Let's update the tags if they have been modified
      this.device.tags = [];
      for (const tag_element of this.tags) {
        this.device.tags.push(tag_element.text);
      }

      axios
        .put('/devices/' + this.$route.params.id, this.device)
        .then((res) => {
          this.submitButtonState = 'success';
          //this.raiseSuccessAlert('Successfully updated your Device!');
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          // this.$router.push('/devices');
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    removeDevice(userConfirmed) {
      console.log('removeDevice() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveDevice = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';
        this.device.install = false;
        axios
          .put('/devices/' + this.$route.params.id, this.device)
          .then((res) => {
            this.raiseSuccessAlert('Successfully removed your Device!');
            this.serverResponse = JSON.stringify(res, null, 2);
            this.deleteButtonState = 'success';
            setTimeout(() => {
              (this.deleteButtonState = 'none'), this.$router.push('/devices');
            }, 2000);
          })
          .catch((err) => {
            this.raiseErrorAlert(err);
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.deleteButtonState = 'error';
            setTimeout(() => {
              this.deleteButtonState = 'none';
            }, 5000);
          });
      }
    },
    refresh() {
      this.showLoading = true;
      axios
        .get('/devices/' + this.$route.params.id)
        .then((res) => {
          console.log(res.data);
          this.device = res.data.data;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .then((res) => {
          //Just to give some feeling of action to the user
          //Without this, it feels too quick and there's no sense of change
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        })
        .catch((err) => {
          this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    useCurrentLocation() {
      if (!navigator.geolocation) {
        this.geoMessage = 'Geolocation is not supported by your browser';
      } else {
        this.geoMessage = 'Locating…';
        navigator.geolocation.getCurrentPosition(
          this.geoSuccess,
          this.geoError
        );
      }
    },
    geoSuccess(position) {
      this.latitude = position.coords.latitude.toFixed(2);
      this.longitude = position.coords.longitude.toFixed(2);
      this.geoMessage = this.latitude + ', ' + this.longitude;

      this.center.lat = position.coords.latitude;
      this.center.lng = position.coords.longitude;
      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },

    geoError() {
      this.geoMessage = 'Error determining location';
    },

    refreshLocation() {
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      console.log(
        'refresh location called - geoMessage: ' +
          this.center.lat +
          ':' +
          this.center.lng
      );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },
    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
    validateDevEuiID(event) {
      const regex = /^[A-Z0-9]+$/;
      if (regex.test(event.target.value)) {
        this.euiIDValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.euiIDValidationError = true;
      }
    },
  },

  created() {
    // console.log('created() called with: ' + this.$route);
    // console.log('this.$route.path:' + this.$route.path);

    this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

    // if (this.$route.path === '/devices/new') {
    //   console.log('Installing new device');
    //   this.installDeviceButton = true;
    // } else
    // {
    axios
      .get('/devices/' + this.$route.params.id)
      .then((res) => {
        console.log(res.data);
        this.device = res.data.data;
        this.center.lat = this.device.latitude;
        this.center.lng = this.device.longitude;
        this.geoMessage = this.center.lat + ',' + this.center.lng;
        this.showMap = true;
        this.serverResponse = JSON.stringify(res, null, 2);

        axios
          .get('/accounts?account_name=' + this.device.core_account_name)
          .then((res) => {
            let account = res.data.data[0];
            for (let i in account.escalation_channel) {
              this.escalation_channels.push(account.escalation_channel[i]);
            }

            for (const tag_group of account.account_tag_groups) {
              console.log('account_tag.group.name: ' + tag_group.name);

              for (const tag of tag_group.tags) {
                let tag_element = {
                  text: tag,
                  style:
                    'color: #000000; background-color: ' +
                    tag_group.color +
                    '; border: 0px',
                };
                this.autocompleteItems.push(tag_element);
                //  console.log('tag: ');
                //  console.log(tag);

                for (const device_tag of this.device.tags) {
                  if (device_tag == tag) {
                    this.tags.push(tag_element);
                  }
                }
              }
            }

            //We have to double check any remaining tags at the device level to see if they're
            //part of the official account tags or not. If not, then we highlight them with a different
            //color scheme (transparent background)

            for (const device_tag of this.device.tags) {
              let found_official_tag = false;

              for (const tag_group of account.account_tag_groups) {
                for (const official_tag of tag_group.tags) {
                  if (device_tag == official_tag) {
                    found_official_tag = true;
                  }
                }
              }

              //We have looped through all of the official tags. If we still didn't find it, then we
              //add it to the list as an unofficial tag with a transparent background
              if (!found_official_tag) {
                this.tags.push({
                  text: device_tag,
                  style:
                    'color: #000000; background-color: transparent; border: 1px solid #4F46E5',
                });
              }
            }

            console.log(this.autocompleteItems);
          })

          .catch((err) => {});
      })

      .catch((err) => {
        this.raiseErrorAlert(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });

    // }
  },
};
</script>
