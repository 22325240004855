<template>
  <div
    class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
  >
    <dt>
      <div class="absolute bg-indigo-500 rounded-md p-3">
        <RssIcon class="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
      </div>

      <p class="ml-16 text-sm font-medium text-gray-500 truncate">
        {{ name }}
      </p>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p v-if="live" class="text-2xl font-semibold text-gray-900">
        {{ value }} {{ unit }}
      </p>
      <p v-else class="text-2xl font-semibold text-red-500">
        ---
      </p>

      <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            View Data Stream</a
          >
        </div>
      </div>
    </dd>
  </div>
</template>

<script>
import { RssIcon } from '@heroicons/vue/outline';

export default {
  props: {
    name: { required: true, type: String },
    value: { required: true, type: Number },
    unit: { required: false, type: String },
    live: { required: true, type: Boolean },
  },
  components: {
    RssIcon,
  },
  data() {
    return {};
  },
};
</script>
