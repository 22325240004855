<template>
  <div class="py-6 px-6">
    <!-- Replace with your content -->

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ gateway.core_gateway_id }}
        </h3>
        <h5 class="text-sm leading-6 font-medium text-gray-500">
          {{ gateway.description }}
        </h5>

        <div class="ml-0 mt-0 ">
          <div class="flex items-center mt-5 mb-2">
            <button
              type="button"
              class=" inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="refresh"
            >
              Refresh
            </button>

            <div
              class="ml-5 px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full"
            >
              Last Seen
              {{
                getTimeDiff(
                  dateMath.diff(
                    new Date(gateway.last_seen),
                    new Date(),
                    'minutes',
                    false
                  )
                )
              }}
            </div>
          </div>
        </div>

        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Devices
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            List of devices that have communicated through this gateway in the
            past 48 hours
          </p>
        </div>

        <div class="mb-4">
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div v-for="device in gateway.active_devices" :key="device.name">
              <gateway-stream-panel
                :name="device.core_device_id"
                :humidity="device.humidity"
                :counter="device.counter"
                :battery="device.battery"
                :door_open="device.door_open"
                :temperature_internal="device.temperature_internal"
                :temperature_probe="device.temperature_probe"
                :eco2="device.eco2"
                :tvoc="device.tvoc"
                :last_seen="device.last_seen"
                :product_name="device.product_name"
                :_id="device._id"
              ></gateway-stream-panel>
            </div>
          </dl>
        </div>

        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Gateway Details
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Edit and save changes to Gateway
          </p>
        </div>

        <div class="border-t border-gray-200">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway Name
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="core_gateway_id"
                      id="core_gateway_id"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.core_gateway_id"
                      v-on:input="validateGatewayName"
                    />
                  </div>
                  <p
                    v-if="gatewayNameValidationError"
                    class="mt-2 text-xs text-red-600"
                  >
                    Name can only contain lowercase letters, numbers and
                    dashes(-)
                  </p>
                </div>
              </dd>
            </div>
            <div
              :class="[
                gateway.live ? 'bg-white' : 'bg-red-400',
                'px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6',
              ]"
            >
              <dt class="text-sm font-medium text-gray-500">Live</dt>
              <dd class="mt-1 text-sm text-gray-900  sm:mt-0 sm:col-span-1">
                <Switch
                  v-model="gateway.live"
                  :class="[
                    gateway.live ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  ]"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      gateway.live ? 'translate-x-5 ' : 'translate-x-0 ',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                    ]"
                  />
                </Switch>
              </dd>
              <dd class="sm:col-span-1">
                <span v-if="!gateway.live" class="text-sm text-white"
                  >If the gateway is no longer live, the SIM card is turned off
                  and any sensors associated with the gateway will no longer
                  transmit data regardless of their 'live' state
                </span>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Tags</dt>

              <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                <div class="flex">
                  <div class="mb-2 italic text-gray-500">
                    Input tags for &nbsp;
                  </div>
                  <div
                    v-for="group in account.account_tag_groups"
                    :key="group"
                    class="mb-2 italic text-gray-500"
                  >
                    {{ group.name }} ,
                  </div>
                </div>
                <div>
                  <vue-tags-input
                    v-model="tag"
                    :tags="tags"
                    :autocomplete-items="filteredItems"
                    @tags-changed="(newTags) => (tags = newTags)"
                  />
                </div>
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">SIM Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="sim_status"
                      id="sim_status"
                      disabled
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="sim_status"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway EUI
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_eui"
                      id="gateway_eui"
                      disabled
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_eui"
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Serial Number</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <input
                      type="text"
                      name="gateway_serial"
                      id="gateway_serial"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.gateway_serial"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Gateway Description
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
                <div>
                  <div
                    class="mt-1 lg:w-6/12 sm:w-full relative rounded-md shadow-sm"
                  >
                    <textarea
                      rows="3"
                      name="description"
                      id="description"
                      class="block w-full pr-10 border-gray-200 sm:text-sm rounded-md"
                      v-model="gateway.description"
                    />
                  </div>
                </div>
              </dd>
            </div>

            <!-- Start Alerts component -->
            <div
              class="bg-gray-50 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Alerts</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                <div class="flex justify-end p-2 pr-5">
                  <button
                    type="button"
                    v-on:click="addAlert"
                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Add Alert
                  </button>
                </div>
                <ul class="border=0 border-gray-200 rounded-md">
                  <!-- Alert list -->

                  <li
                    class="bg-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm border-2 border-white"
                    v-for="(alert, position) in gateway.alerts"
                    :key="position"
                  >
                    <!-- Start Alert (move to component in the future -->

                    <form class="space-y-8 divide-y divide-gray-200">
                      <div class="space-y-8 divide-y divide-gray-200">
                        <div class="pt-8">
                          <div class="sm:col-span-2 justify-self-center">
                            <p>Active</p>
                            <Switch
                              v-model="alert.active"
                              :class="[
                                alert.active ? 'bg-indigo-600' : 'bg-white',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                              ]"
                            >
                              <span class="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                :class="[
                                  alert.active
                                    ? 'translate-x-5 '
                                    : 'translate-x-0 ',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                                ]"
                              />
                            </Switch>
                          </div>

                          <div
                            class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                          >
                            <div class="sm:col-span-6">
                              <label
                                for="street-address"
                                class="block text-sm font-medium text-gray-700"
                              >
                                Alert Name
                              </label>
                              <div class="mt-1">
                                <input
                                  type="text"
                                  v-model="alert.name"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pt-8"
                        >
                          <div class="sm:col-span-6">
                            <h3
                              class="text-lg leading-6 font-medium text-gray-900"
                            >
                              Notification Channels
                            </h3>
                            <p class="mt-1 text-sm text-gray-500">
                              Indicate how you would like to be notified. We
                              integrate with PagerDuty for escalations - please
                              click here for more information.
                            </p>
                          </div>

                          <div class="sm:col-span-4 sm:col-start-1">
                            <div class="mt-0">
                              <label
                                class="block text-sm font-medium text-gray-700"
                              >
                                Select Esclation Channel
                              </label>
                              <div class="mt-1">
                                <select
                                  v-model="alert.escalation_channel"
                                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                >
                                  <option
                                    v-for="channel in escalation_channels"
                                    :key="channel.name"
                                    :value="{
                                      provider: channel.provider,
                                      name: channel.name,
                                      api_key: channel.api_key,
                                      routing_key: channel.routing_key,
                                      email_notification:
                                        channel.email_notification,
                                    }"
                                    >[{{ channel.provider }}]
                                    {{ channel.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="pt-5">
                        <div class="flex justify-end">
                          <div class="justify-self-end">
                            <button
                              type="button"
                              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              v-on:click="deleteAlert(position)"
                            >
                              Delete Alert
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <!--  End of Alert component  -->
                  </li>
                </ul>
              </dd>
            </div>
            <!-- Finish Alerts component  -->

            <!-- Start map component   -->

            <div class="bg-gray-50  mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
              >
                <label
                  class="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 pl-5"
                >
                  Location
                </label>

                <div class="mt-1 flex rounded-md shadow-sm">
                  <span
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm',
                    ]"
                    @click="useCurrentLocation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    v-model="geoMessage"
                    :class="[
                      storeLocation ? 'text-gray-700' : 'text-gray-200',
                      'flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300',
                    ]"
                  />

                  <div class="ml-4 mt-2">
                    <p class="text-sm text-gray-500 ">
                      <RefreshIcon
                        class="flex-shrink-0 h-5 w-5 text-gray-400 "
                        aria-hidden="true"
                        @click="refreshLocation"
                      />
                    </p>
                  </div>
                </div>

                <div
                  class="mt-2 sm:col-span-1 align-middle justify-self-center"
                >
                  <Switch
                    v-model="storeLocation"
                    :class="[
                      storeLocation ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        storeLocation ? 'translate-x-5 ' : 'translate-x-0 ',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 appearance-none',
                      ]"
                    />
                  </Switch>
                </div>
              </div>

              <div
                class="mt-2 sm:col-span-3 align-middle justify-self-center border"
              >
                <GoogleMap
                  :api-key="googleMapsAPIKey"
                  style="width: 100%; height: 500px"
                  :center="center"
                  :zoom="12"
                  v-if="showMap && storeLocation"
                >
                  <Marker :options="{ position: center }" />
                </GoogleMap>
              </div>
            </div>
            <!-- End Map component -->
          </dl>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <!-- <button
              type="button"
              v-on:click="confirmRemoveGateway = true"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Delete Gateway
            </button> -->
            <feedback-button
              v-if="deleteButtonState == 'none'"
              v-on:click="confirmRemoveGateway = true"
              :text="'Delete Gateway'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'success'"
              :state="'success'"
              :text="'Deleted!'"
            ></feedback-button>
            <feedback-button
              v-else-if="deleteButtonState == 'error'"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>

            <confirm-action
              v-if="confirmRemoveGateway"
              :title_text="'Delete Gateway'"
              :message_text="
                'Are you sure you want to Delete this Gateway? This action cannot be undone!'
              "
              :confirm_button_text="'Delete Gateway'"
              @userAction="removeGateway($event)"
            ></confirm-action>

            <feedback-button
              v-if="submitButtonState == 'none'"
              v-on:click="updateGateway"
              :text="'Submit'"
              :state="'none'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'loading'"
              :state="'loading'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'success'"
              :state="'success'"
              :text="'Done!'"
            ></feedback-button>
            <feedback-button
              v-else-if="submitButtonState == 'error'"
              :state="'error'"
              :text="'Error!'"
            ></feedback-button>

            <!-- <button
              type="submit"
              v-on:click="updateGateway"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button> -->
          </div>
        </div>
      </div>

      <!-- /End replace -->
    </div>
  </div>
  <server-response :response="serverResponse"></server-response>
  <loading-widget v-if="showLoading"></loading-widget>
</template>

<script>
import axios from 'axios';
import * as dateMath from 'date-arithmetic';
import { Switch } from '@headlessui/vue';
import ServerResponse from '../components/ServerResponse';
import AlertBanner from '../components/AlertBanner';
import { GoogleMap, Marker } from 'vue3-google-map';
import LoadingWidget from '../components/LoadingWidget.vue';
import GatewayStreamPanel from '../components/GatewayStreamPanel.vue';
import FeedbackButton from '../components/FeedbackButton.vue';
import ConfirmAction from '../components/ConfirmAction.vue';
import VueTagsInput from '@sipec/vue3-tags-input';

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  PaperClipIcon,
  RssIcon,
  CogIcon,
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    PaperClipIcon,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    RssIcon,
    Switch,
    ExclamationCircleIcon,
    CheckCircleIcon,
    CogIcon,
    RefreshIcon,
    ServerResponse,
    AlertBanner,
    GoogleMap,
    Marker,
    LoadingWidget,
    GatewayStreamPanel,
    FeedbackButton,
    ConfirmAction,
    VueTagsInput,
  },

  data() {
    return {
      gateway: {
        core_gateway_id: '',
        gateway_eui: '',
        gateway_serial: '',
        core_account_name: '',
        attributes: {
          core_model: 'core_gateway_01',
        },
        latitude: 0,
        longitude: 0,
        sim_eid: '',
        sim_iccid: '',
        live: true,
        active_devices: [],
        last_billed: '',
      },
      dateMath,
      serverResponse: '',
      gatewayNameValidationError: false,
      // addingGateway: false,
      geoMessage: '',
      storeLocation: true,
      center: { lat: 0, lng: 0 },
      showMap: false,
      sim_status: '',
      googleMapsAPIKey: '',
      showLoading: false,
      escalation_channels: [],
      device_list: [],
      account: {},
      submitButtonState: 'none', // other values include 'loading', 'success', 'error'
      deleteButtonState: 'none', // other values include 'loading', 'success', 'error'
      confirmRemoveGateway: false,
      confirmUserAction: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
    };
  },
  computed: {
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },

  methods: {
    addAlert() {
      this.gateway.alerts.unshift({
        name: 'New alert name',
        active: false,
        escalation: {
          provider: 'pager duty',
          path: { name: '', key: '' },
        },
      });
    },
    deleteAlert(position) {
      console.log('Deleting Alert from position ' + position);
      this.gateway.alerts.splice(position, 1);
    },
    validateGatewayName(event) {
      // const regex = /^[a-z0-9-]+$/;
      // if (regex.test(event.target.value)) {
      //   this.gatewayNameValidationError = false;
      //   //console.log(event.target.value + ': ' + true);
      // } else {
      //   //console.log(event.target.value + ': ' + false);
      //   this.gatewayNameValidationError = true;
      // }

      let updatedName = event.target.value;
      updatedName = updatedName.toLowerCase();

      const regex = /^[a-z0-9-]+$/;
      if (regex.test(updatedName)) {
        this.gatewayNameValidationError = false;
        //console.log(event.target.value + ': ' + true);
      } else {
        //console.log(event.target.value + ': ' + false);
        this.gatewayNameValidationError = true;
      }

      this.gateway.core_gateway_id = updatedName;
    },

    updateGateway() {
      this.submitButtonState = 'loading';
      //Let's set the location data on the device
      if (this.storeLocation) {
        this.gateway.latitude = this.center.lat;
        this.gateway.longitude = this.center.lng;
      }

      //Let's update the tags if they have been modified
      this.gateway.tags = [];
      for (const tag_element of this.tags) {
        this.gateway.tags.push(tag_element.text);
      }

      axios
        .put('/gateways/' + this.$route.params.id, this.gateway)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          this.submitButtonState = 'success';
          this.serverResponse = JSON.stringify(res, null, 2);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
        })
        .catch((err) => {
          this.submitButtonState = 'error';
          this.raiseErrorAlert(err);
          setTimeout(() => {
            this.submitButtonState = 'none';
          }, 5000);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    // addGateway() {
    //   console.log(
    //     'Adding the following gateway : gateway_serial :' + this.gateway_serial
    //   );
    //   //Let's set the location data on the device
    //   if (this.storeLocation) {
    //     this.gateway.latitude = this.center.lat;
    //     this.gateway.longitude = this.center.lng;
    //   }

    //   axios
    //     .post('/gateways', this.gateway)
    //     .then((res) => {
    //       this.serverResponse = JSON.stringify(res, null, 2);
    //       this.raiseSuccessAlert('Successfully added your gateway');
    //       // this.$router.push('/gateways');
    //     })
    //     .catch((err) => {
    //       this.serverResponse = JSON.stringify(err.response, null, 2);
    //       this.raiseErrorAlert(err);
    //       //  this.$router.push('/gateways');
    //     });
    // },

    removeGateway(userConfirmed) {
      console.log('removeDevice() called with userConfirmed: ' + userConfirmed);
      this.confirmRemoveGateway = false;

      if (userConfirmed == true) {
        this.deleteButtonState = 'loading';

        //We will only ever be updating the gateway, not actually deleting it
        this.gateway.install = false;
        axios
          .put('/gateways/' + this.$route.params.id, this.gateway)
          .then((res) => {
            this.serverResponse = JSON.stringify(res, null, 2);
            this.raiseSuccessAlert('Successfully removed your gateway');
            this.deleteButtonState = 'success';
            setTimeout(() => {
              (this.deleteButtonState = 'none'), this.$router.push('/gateways');
            }, 2000);
          })
          .catch((err) => {
            this.serverResponse = JSON.stringify(err.response, null, 2);
            this.raiseErrorAlert(err);
            this.deleteButtonState = 'error';
            setTimeout(() => {
              this.deleteButtonState = 'none';
            }, 5000);
          });
      }
    },

    getSIMStatus(iccid) {
      const headers = {
        Authorization: process.env.VUE_APP_CONNECTED_YOU_SIM_API_KEY,
      };

      axios
        .get('/proxy/sim-status?gateway_iccid=' + iccid, { headers })
        .then((res) => {
          this.sim_status = res.data.data.status;
          this.serverResponse = JSON.stringify(res, null, 2);
        })
        .catch((err) => {
          this.sim_status = 'processing...';
          //this.raiseErrorAlert(err);
          this.serverResponse = JSON.stringify(err.response, null, 2);
        });
    },

    refresh() {
      this.showLoading = true;

      axios
        .get(
          process.env.VUE_APP_TTI_GATEWAY_URL +
            this.gateway.tti_gateway_id +
            process.env.VUE_APP_TTI_GATEWAY_REQUEST,
          {
            headers: {
              authorization: `Bearer ${process.env.VUE_APP_TTI_GATEWAY_AUTHORIZATION}`,
            },
          }
        )
        .then((res) => {
          //Update this code to increment Stripe billing if this is the first time this
          //device has been seen this month. If we don't do this here, then the database will
          //be updated with this month's data point and the gateway cron will not see it as a
          //new gateway. This normall happens at install time
          let previous_last_seen_date = null;
          let now = new Date();

          if (this.gateway.last_seen != null) {
            previous_last_seen_date = new Date(this.gateway.last_seen);
          }

          //Now that we have the previous last_seen recorded, let's get the latest last_seen
          //and compare
          //However, let's only update the value if it's not null. This will prevent the new date
          //going to null when gateways are down, and then incrementing billing when they come back online
          if (res.data.last_status_received_at != null) {
            this.gateway.last_seen = res.data.last_status_received_at;
          }

          //If the previous_last_seen_date is null, then we are installing this for the first time
          console.log('previous_last_seen_date: ' + previous_last_seen_date);
          if (previous_last_seen_date != null) {
            console.log(
              'previous_last_seen_date.getMonth(): ' +
                previous_last_seen_date.getMonth()
            );
          }
          console.log('now.getMonth(): ' + now.getMonth());
          if (
            previous_last_seen_date == null ||
            previous_last_seen_date.getMonth() != now.getMonth()
          ) {
            //Let's update Stripe
            //First, let's get the subscription information from the account
            console.log(
              "We should now be updating Stripe Billing - let's check for a subscription"
            );
            console.log(
              'this.account.stripe_gateway_subscription_id: ' +
                this.account.stripe_gateway_subscription_id
            );
            console.log(this.account);
            if (this.account.stripe_gateway_subscription_id != null) {
              axios
                .post('/proxy/create-stripe-usage-record', {
                  subscription_id: this.account.stripe_gateway_subscription_id,
                  quantity: 1,
                  action: 'increment',
                  timestamp: `${Math.floor(new Date().getTime() / 1000)}`,
                })
                .then((res) => {
                  //Let's update the device object with a timestamp of when it was last billed
                  this.gateway.last_billed = now;
                  console.log(
                    'Just updated Stripe billing with new Usage Record'
                  );
                  console.log('last billed: ' + this.gateway.last_billed);
                  console.log('response');
                  console.log(res);
                })
                .catch((err) => {
                  console.log('Hit Error with Stripe Request');
                  console.log(err.message);
                  console.log(err);
                });
            } else {
              console.log('No Stripe Billing Subsciription Account');
            }
          }

          //Let's update the database with the latest inforamtion
          this.updateGateway();

          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        });
    },

    useCurrentLocation() {
      if (!navigator.geolocation) {
        this.geoMessage = 'Geolocation is not supported by your browser';
      } else {
        this.geoMessage = 'Locating…';
        navigator.geolocation.getCurrentPosition(
          this.geoSuccess,
          this.geoError
        );
      }
    },
    geoSuccess(position) {
      this.latitude = position.coords.latitude.toFixed(2);
      this.longitude = position.coords.longitude.toFixed(2);
      this.geoMessage = this.latitude + ', ' + this.longitude;

      this.center.lat = position.coords.latitude;
      this.center.lng = position.coords.longitude;
      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },

    geoError() {
      this.geoMessage = 'Error determining location';
    },
    getTimeDiff(time_diff) {
      //Let's round down the timing
      // If it's less than 60 mins, then we use the minute value
      // If it's more than 60 mins and less than 1440 mins, then we use the lowest hour
      // if it's more than 1440 mins then we use the lowest day

      let returnLastSeen = '';
      console.log('time_diff: ' + time_diff);

      if (time_diff < 60) {
        returnLastSeen = time_diff + ' mins ago';
      } else if (time_diff >= 60 && time_diff < 1440) {
        returnLastSeen = Math.floor(time_diff / 60) + ' hours ago';
      } else {
        returnLastSeen = Math.floor(time_diff / 1440) + ' days ago';
      }
      return returnLastSeen;
    },

    refreshLocation() {
      var coords = this.geoMessage.split(',');
      this.center.lat = parseFloat(coords[0]);
      this.center.lng = parseFloat(coords[1]);

      console.log(
        'refresh location called - geoMessage: ' +
          this.center.lat +
          ':' +
          this.center.lng
      );

      //refresh the map
      this.showMap = false;
      setTimeout(() => {
        console.log('Reloading Google Map');
        this.showMap = true;
      }, 500);
    },

    raiseErrorAlert(err) {
      let error_message = '';
      if (err.response) {
        error_message = err.response.data.error;
      } else {
        error_message = err.message;
      }

      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'error',
        message: error_message,
      });
    },
    raiseSuccessAlert(message) {
      this.$store.commit({
        type: 'updateUserAlert',
        visible: true,
        alert_type: 'success',
        message: message,
      });
    },
  },

  created() {
    console.log('created() called with: ' + this.$route);
    console.log('this.$route.path:' + this.$route.path);

    this.googleMapsAPIKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    console.log(
      'process.env.VUE_APP_GOOGLE_MAPS_API_KEY: ' +
        process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    );
    console.log(
      'process.env.VUE_APP_TTI_GATEWAY_URL: ' +
        process.env.VUE_APP_TTI_GATEWAY_URL
    );

    axios
      .get('/gateways/' + this.$route.params.id)
      .then((res) => {
        console.log(res.data);
        this.gateway = res.data.data;
        this.center.lat = this.gateway.latitude;
        this.center.lng = this.gateway.longitude;
        this.geoMessage = this.center.lat + ',' + this.center.lng;
        this.showMap = true;
        this.getSIMStatus(this.gateway.sim_iccid);
        this.serverResponse = JSON.stringify(res, null, 2);

        axios
          .get('/accounts?account_name=' + this.gateway.core_account_name)
          .then((res) => {
            this.account = res.data.data[0];
            for (let i in this.account.escalation_channel) {
              this.escalation_channels.push(this.account.escalation_channel[i]);
            }

            for (const tag_group of this.account.account_tag_groups) {
              console.log('account_tag.group.name: ' + tag_group.name);

              for (const tag of tag_group.tags) {
                let tag_element = {
                  text: tag,
                  style:
                    'color: #000000; background-color: ' +
                    tag_group.color +
                    '; border: 0px',
                };
                this.autocompleteItems.push(tag_element);
                console.log('tag: ');
                console.log(tag);
                console.log('this.gateway.tags: ');
                console.log(this.gateway.tags);

                for (const gateway_tag of this.gateway.tags) {
                  if (gateway_tag == tag) {
                    this.tags.push(tag_element);
                  }
                }
              }
            }

            //We have to double check any remaining tags at the device level to see if they're
            //part of the official account tags or not. If not, then we highlight them with a different
            //color scheme (transparent background)

            for (const device_tag of this.device.tags) {
              let found_official_tag = false;

              for (const tag_group of this.account.account_tag_groups) {
                for (const official_tag of tag_group.tags) {
                  if (device_tag == official_tag) {
                    found_official_tag = true;
                  }
                }
              }

              //We have looped through all of the official tags. If we still didn't find it, then we
              //add it to the list as an unofficial tag with a transparent background
              if (!found_official_tag) {
                this.tags.push({
                  text: device_tag,
                  style:
                    'color: #000000; background-color: transparent; border: 1px solid #4F46E5',
                });
              }
            }

            console.log(this.autocompleteItems);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        console.log(err);
        this.serverResponse = JSON.stringify(err.response, null, 2);
      });
    //    }
  },
};
</script>
