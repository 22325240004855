<template>
  <div class="flex items-center ">
    <div class="flex-shrink-0 mt-20 max-w-2xl text-xs text-gray-500 font-mono">
      Server Response
    </div>
    <div class="mt-20 ml-5">
      <p class="text-sm text-gray-500 ">
        <ArrowSmDownIcon
          v-if="!show"
          class="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
          @click="show = !show"
        />
        <ArrowSmUpIcon
          v-if="show"
          class="flex-shrink-0 h-5 w-5 text-gray-400"
          aria-hidden="true"
          @click="show = !show"
        />
      </p>
    </div>
  </div>
  <pre v-if="show" class="mt-1 text-sm text-gray-500 border bg-white font-mono">
      {{ response }}
    </pre
  >
</template>

<script>
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';

export default {
  props: {
    response: { required: true, type: String },
  },
  components: {
    ArrowSmDownIcon,
    ArrowSmUpIcon,
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
